import { Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReservationInfo } from '../../models';
import { selectLocations } from '../../store/locationsSlice';
import useStyles from '../../styles';
import ReservedItem from '../item/ReservedItem';
import '../../Results.css';

interface ReservationResultsProps {
  infos: ReservationInfo[];
  redirectUrl: string;
  adminPage?: boolean;
  reload?: () => void;
}

function ReservationResults(props: ReservationResultsProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const [showActivableBorrowings, setShowActivableBorrowings] = useState(false);
  const [showUpcomingReservations, setShowUpcomingReservations] = useState(false);
  const upcomingReservations: ReservationInfo[] = props.infos.filter(
    (info: ReservationInfo) =>
      Date.parse(info.reservation.startDate) > new Date().getTime() &&
      !info.reservation.fullfilled &&
      !info.reservation.removed,
  );
  const activableBorrowings: ReservationInfo[] = props.infos.filter(
    (info: ReservationInfo) =>
      Date.parse(info.reservation.startDate) < new Date().getTime() &&
      !info.reservation.fullfilled &&
      !info.reservation.removed,
  );

  const endedReservations: ReservationInfo[] = props.infos.filter(
    (info: ReservationInfo) => Date.parse(info.reservation.endDate) < new Date().getTime(),
  );

  const displayResultText = (): string => (props.adminPage ? '' : t('search.browse.reservations'));

  const sortItemsByDate = (i1: ReservationInfo, i2: ReservationInfo): number =>
    i1.reservation.startDate < i2.reservation.startDate ? 1 : -1;

  const createItemGrid = (reservationInfos: ReservationInfo[], altTitle?: string): JSX.Element => {
    return (
      <div>
        {reservationInfos.length !== 0 && (
          <div className={classes.flexRow}>
            <h2>{altTitle ?? displayResultText()}</h2>
          </div>
        )}
        <Grid container className={classes.fGrow} spacing={2}>
          {reservationInfos.sort(sortItemsByDate).map((info: ReservationInfo, i: number) => {
            return (
              <Grid item className={classes.gridItem} key={i} xl={6} lg={6} md={12} sm={12} xs={12}>
                <ReservedItem
                  key={info.reservation.reservationId}
                  info={info}
                  reservationStatus={info.itemInstance.available}
                  redirectUrl={props.redirectUrl}
                  adminPage={props.adminPage}
                  reload={props.reload}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  };

  return (
    <div>
      <div className="divWithMargin">
        {activableBorrowings.length !== 0 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowActivableBorrowings(!showActivableBorrowings)}
          >
            {showActivableBorrowings
              ? t('reservation.hideActiveReservations')
              : t('reservation.toggleActiveReservations')}
          </Button>
        )}
        {showActivableBorrowings && createItemGrid(activableBorrowings)}
      </div>

      <div className="divWithMargin">
        {upcomingReservations.length !== 0 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowUpcomingReservations(!showUpcomingReservations)}
          >
            {showUpcomingReservations
              ? t('reservation.hideUpcomingReservations')
              : t('reservation.toggleUpcomingReservations')}
          </Button>
        )}
        {showUpcomingReservations && createItemGrid(upcomingReservations)}
      </div>
    </div>
  );
}
export default ReservationResults;
