import { Typography } from '@material-ui/core';
import numeral from 'numeral';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemInstance } from '../../models';
import useStyles from '../../styles';

interface PriceDisplayProps {
  instance: ItemInstance;
  instance2?: ItemInstance;
  style?: CSSProperties;
  showTitle?: boolean;
  showOnlyPrice?: boolean;
  price?: number;
}
function PriceDisplay({
  instance,
  style,
  instance2,
  showTitle = true,
  showOnlyPrice = false,
  price,
}: PriceDisplayProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div style={style}>
      {showTitle && (
        <Typography variant="subtitle2" component="span">
          {t('data.fields.price')}:
        </Typography>
      )}
      {showOnlyPrice ? (
        <Typography variant={'subtitle1'} className={classes.priceDisplay}>
          <strong>{price}</strong> €
        </Typography>
      ) : (
        <>
          <Typography variant={'subtitle1'} className={classes.priceDisplay}>
            <strong>{t('data.fields.day')}:</strong>{' '}
            <span className={classes.priceInt}>{numeral(Math.floor(instance.price || 0)).format('0')}</span>
            <span className={classes.priceDec}>{numeral(instance.price || 0).format('.00')}</span>&nbsp;
            {instance2 ? null : <strong>€</strong>}
            {instance2 && (
              <span>
                {' '}
                - <span className={classes.priceInt}>{numeral(Math.floor(instance2.price || 0)).format('0')}</span>
                <span className={classes.priceDec}>{numeral(instance2.price || 0).format('.00')}</span> €
              </span>
            )}
          </Typography>
          <Typography variant={'subtitle1'} className={classes.priceDisplay}>
            <strong>{t('data.fields.extraDay')}: +</strong>{' '}
            <span className={classes.priceInt}>{numeral(Math.floor(instance.pricePerExtraDay || 0)).format('0')}</span>
            <span className={classes.priceDec}>{numeral(instance.pricePerExtraDay || 0).format('.00')}</span>{' '}
            {instance2 ? null : <strong>€</strong>}
            {instance2 && (
              <span>
                {' '}
                -{' '}
                <span className={classes.priceInt}>
                  {numeral(Math.floor(instance2.pricePerExtraDay || 0)).format('0')}
                </span>
                <span className={classes.priceDec}>{numeral(instance2.pricePerExtraDay || 0).format('.00')}</span> €
              </span>
            )}
          </Typography>
        </>
      )}
    </div>
  );
}

export default PriceDisplay;
