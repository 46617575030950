import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BackButton, CreateButton } from '../../components';
import InstanceForm from '../../components/admin/InstanceForm';
import { errorInInstance, errorInPrice } from '../../components/admin/validation';
import { URLS } from '../../constants';
import { ItemInstance, ItemInstanceDTO, Location } from '../../models';
import { itemService, locationService } from '../../services';
import { createErrorNotification } from '../../services/helpers';
import { selectUser } from '../../store/userSlice';
import useStyles from '../../styles';

function AdminNewItemView(props: any): JSX.Element {
  const itemModelId: number = props.match.params.itemId;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [itemInstanceDto, setItemInstanceDto] = useState<ItemInstanceDTO>({
    itemModelId: Number(itemModelId),
    size: '',
    locationId: 0,
    pretaxPrice: 0,
    price: 0,
    pricePerExtraDay: 0,
    priceDiscountPercent: 0,
    tax: 24,
  });

  const [instance, setInstance] = useState<ItemInstance>({
    itemModelId: Number(itemModelId),
    itemInstanceId: 0,
    size: '',
    locationId: 0,
    available: false,
    borrowedByMe: false,
    nextBorrowingDueDate: '',
    tax: 24,
    price: 0,
    priceDiscountPercent: 0,
    pricePerExtraDay: 0,
    pretaxPrice: 0,
  });

  const [locations, setLocations]: [Location[], any] = useState([]);
  // Allow location selector to be controlled
  const [currentLocation, setCurrentLocation]: [string, any] = useState('');
  const userId: string = useSelector(selectUser).id ?? '';

  useEffect(() => {
    locationService.fetchAll().then((locations) => setLocations(locations));
    //console.debug('Called effect for locations');
  }, []);
  useEffect(() => {
    setItemInstanceDto({
      ...itemInstanceDto,
      pretaxPrice: parseFloat(((itemInstanceDto.price || 0) / (1 + (itemInstanceDto.tax || 0) / 100)).toFixed(2)),
    });
  }, [itemInstanceDto.tax, itemInstanceDto.price]);
  const createItem: () => void = async () => {
    if (errorInPrice(String(itemInstanceDto.price))) {
      createErrorNotification(t('notifications.error.priceMustBeAValidNumber'));
      return;
    }
    const instance: ItemInstance | void = await itemService.createOneInstance(userId, itemInstanceDto);
    if (instance) {
      history.push(`${URLS.ADMIN.ITEM}/${itemModelId}`);
    }
  };

  const checkError: () => boolean = () => {
    return errorInInstance(itemInstanceDto);
  };

  const header: JSX.Element = (
    <div className={classes.flexRow}>
      <h1>{t('admin.createNewSize')}</h1>
      <div className={classes.flexRow}>
        <div className={classes.rMargin}>
          <CreateButton create={createItem} checkError={checkError} />
        </div>
        <BackButton />
      </div>
    </div>
  );

  return (
    <div>
      {header}
      <InstanceForm
        instanceId={0}
        itemModelId={itemModelId}
        initializeWithDto={true}
        locationName={''}
        locations={locations}
        currentLocation={currentLocation}
        setCurrentLocation={setCurrentLocation}
        instanceDto={itemInstanceDto}
        setInstanceDto={setItemInstanceDto}
        instance={instance}
      />
    </div>
  );
}

export default AdminNewItemView;
