import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BorrowingResults } from '../../components';
import { URLS } from '../../constants';
import { Borrowing, BorrowingInfo, ItemInstance, Reservation, ReservationInfo, User } from '../../models';
import { borrowingService, itemService, reservationService, userService } from '../../services';
import { RootState } from '../../store';
import { selectLocations } from '../../store/locationsSlice';
import ReservationResults from '../../components/result-cards/ReservationResults';
import ServiceBreakComponent from '../../components/admin/ServiceBreak';

function AdminBorrowingsView(): JSX.Element {
  const { t } = useTranslation();
  const userId = useSelector((state: RootState) => state.user.id);
  const [borrowings, setBorrowings] = useState<Borrowing[]>([]);
  const [borrowingInfos, setBorrowingInfos] = useState<BorrowingInfo[]>([]);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [reservationInfos, setReservationInfos] = useState<ReservationInfo[]>([]);
  const locations = useSelector(selectLocations);
  const [showActivableBorrowings, setShowActivableBorrowings] = useState(false);
  const [showUpcomingReservations, setShowUpcomingReservations] = useState(false);

  const reloadBorrowing = () => {
    borrowingService.fetchAllActive().then((borrowingsResponse: Borrowing[] | void) => {
      if (borrowingsResponse) {
        setBorrowings(borrowingsResponse);
      }
    });
  };

  const reloadReservation = () => {
    reservationService.fetchAllActive().then((reservationsResponse: Reservation[] | void) => {
      if (reservationsResponse) {
        setReservations(reservationsResponse);
      }
    });
  };

  // Fetch active borrowings in the beginning
  useEffect(() => {
    // Route is protected, user has to be logged in
    reloadBorrowing();
    reloadReservation();
  }, [userId]);

  const [users, setUsers]: [User[], any] = useState([]);

  useEffect(() => {
    userService.fetchAll().then((users: User[] | void) => {
      setUsers(users ?? []);
    });
  }, []);
  // Fetch all liked item objects when likes are fetched
  useEffect(() => {
    const borrowedIds = borrowings.map((borr) => borr.borrowedItemId);
    if (borrowedIds.length !== 0) {
      // TODO: Fetch by list or something
      itemService.fetchAllInstances().then((itemsResponse: ItemInstance[] | void) => {
        // Pair every borrowing with corresponding item to get all needed information
        if (itemsResponse) {
          const borrowingInfos: BorrowingInfo[] = [];
          const borrowedItems: ItemInstance[] = itemsResponse.filter((instance: ItemInstance) =>
            borrowedIds.includes(instance.itemInstanceId),
          );
          borrowings.forEach((borr: Borrowing) => {
            const correspondingItem: ItemInstance | undefined = borrowedItems.find(
              (instance: ItemInstance) => instance.itemInstanceId === borr.borrowedItemId,
            );
            const borrowerId: string = borr.borrowerId;
            if (correspondingItem) {
              borrowingInfos.push({ itemInstance: correspondingItem, borrowing: borr, borrowerId: borrowerId });
            }
          });
          setBorrowingInfos(borrowingInfos);
        }
      });
    }
  }, [borrowings]);

  useEffect(() => {
    const reservedIds = reservations.map((reser) => reser.reservedItemInstanceId);
    if (reservedIds.length !== 0) {
      itemService.fetchAllInstances().then((itemsResponse: ItemInstance[] | void) => {
        if (itemsResponse) {
          const reservationInfos: ReservationInfo[] = [];
          const reservedItems: ItemInstance[] = itemsResponse.filter((instance: ItemInstance) =>
            reservedIds.includes(instance.itemInstanceId),
          );
          reservations.forEach((reser: Reservation) => {
            const correspondingItem: ItemInstance | undefined = reservedItems.find(
              (instance: ItemInstance) => instance.itemInstanceId === reser.reservedItemInstanceId,
            );
            const reserverId: string = reser.reserverId;
            if (correspondingItem) {
              reservationInfos.push({ itemInstance: correspondingItem, reservation: reser, reserverId: reserverId });
            }
          });
          setReservationInfos(reservationInfos);
        }
      });
    }
  }, [reservations]);

  const reservationsByLocation: { [key: number]: ReservationInfo[] } = {};
  const borrowingsByLocation: { [key: number]: BorrowingInfo[] } = {};

  useMemo(() => {
    borrowingInfos.forEach((borrowing) => {
      borrowing.user = users.find((user) => user.id === borrowing.borrowerId);
    });
    borrowingInfos.map((borrowing) => {
      if (!borrowingsByLocation[borrowing.itemInstance.locationId || -2])
        borrowingsByLocation[borrowing.itemInstance.locationId || -2] = [];
      borrowingsByLocation[borrowing.itemInstance.locationId || -2].push(borrowing);
    });

    reservationInfos.forEach((reservation) => {
      reservation.user = users.find((user) => user.id === reservation.reserverId);
    });
    reservationInfos.map((reservation) => {
      if (!reservationsByLocation[reservation.itemInstance.locationId || -2])
        reservationsByLocation[reservation.itemInstance.locationId || -2] = [];
      reservationsByLocation[reservation.itemInstance.locationId || -2].push(reservation);
    });
  }, [borrowingInfos, reservationInfos, users]);

  return (
    <div>
      <h1> {t('views.borrowings')}</h1>
      {Object.keys(borrowingsByLocation).map((locationId) => {
        const location = locations.find((l) => l.locationId === parseInt(locationId));
        return (
          <div key={locationId}>
            <h2 style={{ marginTop: '60px', marginBottom: '10px' }}>{location?.name || 'Ei kohdetta'}</h2>
            <BorrowingResults
              reload={reloadBorrowing}
              infos={borrowingsByLocation[parseInt(locationId)]}
              redirectUrl={URLS.EU.INSTANCE_NOQUERY}
              adminPage={true}
            />
          </div>
        );
      })}
      <h1 style={{ marginTop: '60px' }}> {t('views.reservations')}</h1>
      {Object.keys(reservationsByLocation).map((locationId) => {
        const location = locations.find((l) => l.locationId === parseInt(locationId));
        return (
          <div key={locationId}>
            <h2 style={{ marginTop: '60px', marginBottom: '10px' }}>{location?.name || 'Ei kohdetta'}</h2>
            <ReservationResults
              reload={reloadReservation}
              infos={reservationsByLocation[parseInt(locationId)]}
              redirectUrl={URLS.EU.INSTANCE_NOQUERY}
              adminPage={true}
            />
          </div>
        );
      })}
      <ServiceBreakComponent />
    </div>
  );
}

export default AdminBorrowingsView;
