import { Button } from '@material-ui/core';
import { CropFree } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BackButton, RemoveButton, SaveButton } from '../../components';
import InstanceForm from '../../components/admin/InstanceForm';
import { errorInDiscountPercent, errorInInstance, errorInPrice } from '../../components/admin/validation';
import { URLS } from '../../constants';
import { ItemInstance, ItemInstanceDTO, Location } from '../../models';
import { itemService, locationService } from '../../services';
import { createErrorNotification } from '../../services/helpers';
import { selectUser } from '../../store/userSlice';
import useStyles from '../../styles';
import { QrCodeDialog } from './../../components/item/QrCodeDialog';

function AdminOldInstanceView(props: any): JSX.Element {
  const itemInstanceId: number = props.match.params.instanceId;
  const itemModelId: number = props.match.params.itemId;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [itemInstanceDto, setItemInstanceDto] = useState<ItemInstanceDTO>({
    itemModelId: Number(itemModelId),
    size: '',
    locationId: 0,
    tax: 0,
    price: 0,
    pricePerExtraDay: 0,
    priceDiscountPercent: 0,
    pretaxPrice: 0,
  });

  const [instance, setInstance] = useState<ItemInstance>({
    itemModelId: Number(itemModelId),
    itemInstanceId: itemInstanceId,
    size: '',
    locationId: 0,
    available: false,
    borrowedByMe: false,
    nextBorrowingDueDate: '',
    tax: 0,
    price: 0,
    priceDiscountPercent: 0,
    pricePerExtraDay: 0,
    pretaxPrice: 0,
  });

  const [locations, setLocations] = useState<Location[]>([]);
  // Allow location selector to be controlled
  const [currentLocation, setCurrentLocation]: [string, any] = useState('');
  const userId: string = useSelector(selectUser).id ?? '';
  const [open, setOpen] = React.useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState<boolean>(false);
  const [locationName, setLocationName]: [string, any] = useState('');

  const showQrCode = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    locationService.fetchAll().then((locations) => {
      if (locations) {
        setLocations(locations);
      }
    });
  }, []);

  useEffect(() => {
    // Fetch item only when locations are loaded. This way the name of the location can be displayed.
    if (locations.length !== 0 && !initialLoadDone) {
      const mapLocationName = (locationId: number): string => {
        const location: Location | undefined = locations.find((location) => location.locationId === locationId);
        return location?.name ?? '';
      };

      itemService.fetchOneInstance(itemInstanceId).then((instance: ItemInstance | void) => {
        if (instance) {
          setItemInstanceDto(instance as ItemInstanceDTO);
          setInstance(instance);
          const loc = locations.find((location) => location.locationId === instance.locationId);
          setCurrentLocation(loc?.name ?? '');
        }
      });

      setInitialLoadDone(true);
    }
  }, [dispatch, initialLoadDone, itemInstanceId, itemModelId, locations]);

  useEffect(() => {
    setItemInstanceDto({
      ...itemInstanceDto,
      pretaxPrice: parseFloat(((itemInstanceDto.price || 0) / (1 + (itemInstanceDto.tax || 0) / 100)).toFixed(2)),
    });
  }, [
    itemInstanceDto.tax,
    itemInstanceDto.price,
    itemInstanceDto.pricePerExtraDay,
    itemInstanceDto.priceDiscountPercent,
  ]);
  const saveInstance: () => void = async () => {
    if (errorInPrice(String(itemInstanceDto.price)) || errorInPrice(String(itemInstanceDto.pricePerExtraDay))) {
      createErrorNotification(t('notifications.error.priceMustBeAValidNumber'));
      return;
    }
    if (errorInDiscountPercent(String(itemInstanceDto.priceDiscountPercent))) {
      createErrorNotification(t('notifications.error.priceDiscountMustBeAValidNumber'));
      return;
    }
    const instance: ItemInstance | void = await itemService.updateOneInstance(userId, itemInstanceDto, itemInstanceId);
    if (instance) {
      history.push(`${URLS.ADMIN.ITEM}/${itemModelId}`);
    }
  };

  const checkError: () => boolean = () => {
    return errorInInstance(itemInstanceDto);
  };

  const removeInstance: () => void = () => {
    itemService.deleteOneInstance(userId, itemInstanceId).then(() => {
      history.push(`${URLS.ADMIN.ITEM}/${itemModelId}`);
    });
  };

  const createQrCodeButton: JSX.Element = (
    <Button variant="contained" color="default" onClick={showQrCode}>
      <CropFree /> {t('actions.showQrCode')}
    </Button>
  );

  const header: JSX.Element = (
    <div className={classes.flexRow}>
      <h1>{t('admin.modify')}</h1>
      <div className={`${classes.flexRow} ${classes.flexReverseRow}`}>
        <SaveButton save={saveInstance} checkError={checkError} />

        <div className={classes.rMargin}>
          <RemoveButton remove={removeInstance} />
        </div>
        <div className={classes.rMargin}>{createQrCodeButton}</div>
        <div className={classes.rMargin}>
          <BackButton />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {header}
      <InstanceForm
        instanceId={itemInstanceId}
        itemModelId={itemModelId}
        initializeWithDto={true}
        locationName={locationName}
        locations={locations}
        currentLocation={currentLocation}
        setCurrentLocation={setCurrentLocation}
        instanceDto={itemInstanceDto}
        setInstanceDto={setItemInstanceDto}
        instance={instance}
      />
      <QrCodeDialog isOpen={open} closeDialog={handleClose} itemInstanceId={itemInstanceId}></QrCodeDialog>
    </div>
  );
}

export default AdminOldInstanceView;
