import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getLocationById } from '../../helpers';
import { Reservation, ReservationInfo, ImageData, ItemInstance, ItemModel } from '../../models';
import { reservationService, imageService, itemService } from '../../services';
import { RootState } from '../../store';
import { selectLocations } from '../../store/locationsSlice';
import { addSkipForImage, selectSkipImageReload } from '../../store/settingsSlice';
import useStyles from '../../styles';
import { createImageData, dummySvg } from '../../util';
import { ConfirmationDialog } from '../index';

interface ReservedItemProps {
  info: ReservationInfo;
  reservationStatus: boolean;
  redirectUrl: string;
  adminPage?: boolean;
  reload?: () => void;
}

function ReservedItem(props: ReservedItemProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [imageData, setImageData] = useState<ImageData | undefined>(undefined);
  const instance: ItemInstance = props.info.itemInstance;
  const skipReload = useSelector((state) => selectSkipImageReload(state as RootState, instance.itemInstanceId));
  const reservation: Reservation = props.info.reservation;
  const reserverId: string | undefined = props.info.reserverId;
  const [openReturnConfirmModal, setOpenReturnConfirmModal] = useState(false);
  const [item, setItem] = useState<ItemModel>({} as ItemModel);
  const locations = useSelector(selectLocations);
  const location = useMemo(() => {
    if (instance) {
      return getLocationById(locations, instance.locationId);
    }
    return null;
  }, [instance, locations]);
  useEffect(() => {
    if (!imageData) {
      imageService.fetchOne(instance.itemModelId || 0, false, skipReload).then((imageBlob: Blob | void) => {
        createImageData(setImageData, imageBlob);
        if (!skipReload) dispatch(addSkipForImage(instance.itemInstanceId));
      });
    }
    itemService.fetchOne(Number(instance.itemModelId)).then((item: ItemModel | void) => {
      if (item) {
        setItem(item);
      }
    });
  }, [dispatch, imageData, instance.itemInstanceId, skipReload]);

  const daysToStart = (): number => {
    const today = new Date();
    const diff = Date.parse(reservation.startDate).valueOf() - today.valueOf();
    return Math.ceil(diff / (1000 * 3600 * 24));
  };

  const endReservation = async (info: ReservationInfo) => {
    if (!props.adminPage) return false;
    if (!info.reservation || !info.reservation?.reserverId || !info.reservation?.reservationId) {
      return false;
    }

    await reservationService.removeReservation(info.reservation.reserverId, info.reservation.reservationId);
    if (props.reload) {
      props.reload();
    }
  };

  const reservationState = (): JSX.Element => {
    const returned = reservation.fullfilled || reservation.removed;
    return returned ? (
      <>
        <Grid item xs={12} md={6}>
          <Typography variant={'subtitle2'}>{t('reservation.startDate')}</Typography>
          <Typography variant={'subtitle1'}>
            <strong>{moment(reservation.startDate).format('DD.MM.YYYY HH:mm')}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant={'subtitle2'}>{t('reservation.fullfilledOn')}</Typography>
          <Typography variant={'subtitle1'}>
            <strong>{moment(reservation.startDate).format('DD.MM.YYYY HH:mm')}</strong>
          </Typography>
        </Grid>
      </>
    ) : (
      <>
        <Grid item xs={12} md={6}>
          <Typography variant={'subtitle2'}>{t('reservation.startDate')}</Typography>
          <Typography variant={'subtitle1'}>
            <strong>{moment(reservation.startDate).format('DD.MM.YYYY HH:mm')}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant={'subtitle2'}>{t('reservation.endDate')}</Typography>
          <Typography variant={'subtitle1'}>
            <strong>{moment(reservation.endDate).format('DD.MM.YYYY HH:mm')}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant={'subtitle2'}>{t('reservation.daysLeft2')}</Typography>
          <Typography variant={'subtitle1'} className={daysToStart() > 3 ? classes.green : classes.orange}>
            <strong>{daysToStart()}</strong>
          </Typography>
        </Grid>
      </>
    );
  };

  return (
    <div>
      <Card
        variant="outlined"
        className={[classes.itemContent, classes.pointer].filter(Boolean).join(' ')}
        onClick={() => {
          history.push(`${props.redirectUrl}/${instance.itemInstanceId}`);
        }}
      >
        <CardMedia
          className={classes.itemContentImageThumb}
          image={imageData?.url ?? dummySvg}
          title="item image"
          style={{ backgroundSize: 'contain', backgroundColor: '#fff' }}
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Typography variant={'h6'}>{item.name}</Typography>
            </Grid>
            {instance.size && (
              <Grid item xs={12} md={6}>
                <Typography variant={'subtitle2'}>{t('instance.size')}:</Typography>
                <Typography variant={'subtitle1'}>
                  <strong>{instance.size}</strong>
                </Typography>
              </Grid>
            )}

            {props.adminPage && (
              <Grid item xs={12} md={6}>
                <Typography variant={'subtitle2'}>Vuokraaja</Typography>
                <Typography variant={'subtitle1'}>
                  <strong>{[props.info.user?.firstName, props.info.user?.lastName].filter(Boolean).join(' ')}</strong>

                  <span></span>
                </Typography>
                <div>
                  <span>{[props.info.user?.email, props.info.user?.phoneNumber].filter(Boolean).join(' ')}</span>
                </div>
              </Grid>
            )}
            {reservationState()}
            {location && (
              <Grid item xs={12} md={6}>
                <Typography variant={'subtitle2'}>{t('instance.location')}:</Typography>
                <Typography variant={'subtitle1'}>
                  <strong>{location?.name ?? ''}</strong>
                </Typography>
              </Grid>
            )}
            {props.adminPage && (
              <Grid item xs={12} className={classes.flexRow}>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  className={classes.block}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpenReturnConfirmModal(true);
                  }}
                >
                  {t('item.actions.removeReservation')}
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <ConfirmationDialog
        title={t('item.actions.removeReservation')}
        open={openReturnConfirmModal}
        onClose={() => setOpenReturnConfirmModal(false)}
        onOk={() => {
          setOpenReturnConfirmModal(false);
          endReservation(props.info);
        }}
      >
        {t('item.markRemoved')}
      </ConfirmationDialog>
    </div>
  );
}

export default ReservedItem;
