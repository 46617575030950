import { Grid, LinearProgress } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ItemComponent } from '..';
import { ItemInstance, ItemModel, Location } from '../../models';
import { selectFilters, selectQuery } from '../../store/searchSlice';
import { selectUser } from '../../store/userSlice';
import useStyles from '../../styles';

interface ItemResultsProps {
  items: ItemModel[];
  instances: ItemInstance[];
  redirectUrl: string;
  actionButton?: JSX.Element;
  resultText?: string;
  location?: Location | null;

  useFilters?: boolean;
  loading: boolean;
  sort?: boolean;
}

function ItemResults(props: ItemResultsProps): JSX.Element {
  const { t } = useTranslation();
  const query = useSelector(selectQuery);
  const classes = useStyles();
  const userLocation = useSelector(selectUser)?.defaultLocationId;

  const filters = useSelector(selectFilters);
  const useFilters = props.useFilters ?? true;
  const filteredItems = props.items.filter((item: ItemModel) => {
    const fAvailable = filters.freeItems ? item.available : true;
    return fAvailable;
  });
  const doSort = props.sort ?? true;

  const sortItemsByName = (i1: ItemModel, i2: ItemModel): number =>
    i1.name.toLowerCase() > i2.name.toLowerCase() ? 1 : -1;

  const items: ItemModel[] = useMemo(() => {
    const _items = props.items
      .filter((item) =>
        filters.freeItems
          ? item.available &&
            filters.ownLocation &&
            userLocation &&
            item.locations.findIndex((l) => l === userLocation) > -1
          : true,
      )
      .filter((item) =>
        props.location !== null && props.location?.locationId
          ? item.locations.filter((i: number) => i === props.location?.locationId).length > 0
          : filters.ownLocation && userLocation
          ? item.locations.length > 0 && item.locations.includes(userLocation)
          : true,
      )
      .map((item: ItemModel) => {
        const _item = { ...item };
        _item.instances = props.instances.filter((instance) => instance.itemModelId === _item.itemModelId);
        return _item;
      });
    doSort && _items.sort(sortItemsByName);

    return _items;
  }, [sortItemsByName, props.items, filters.ownLocation, props.location, userLocation]);

  const displayResultText = (): string => {
    // Override default result text (e.g. in case of likes)
    if (props.resultText) {
      return props.resultText;
    }
    if (!query) {
      return t('search.browse.items');
    }
    switch (filteredItems.length) {
      case 0:
        return t('search.results.none');
      case 1:
        return t('search.results.one');
      default:
        return t('search.results.many', { x: filteredItems.length });
    }
  };

  return (
    <div>
      <div className={classes.flexRow}>
        <h2>{displayResultText()}</h2>
        {props.actionButton !== undefined && props.actionButton}
      </div>
      {props.loading && <LinearProgress />}
      <Grid container className={classes.fGrow} spacing={2}>
        {items.map((item: ItemModel, i: number) => {
          const availableItems: ItemInstance[] = item.instances;
          const available = availableItems.filter((instance) =>
            filters.ownLocation && userLocation ? instance.locationId === userLocation : true,
          ).length;
          return (
            <Grid item className={classes.gridItem} key={i} xl={2} lg={3} md={4} sm={6} xs={12}>
              <ItemComponent
                key={item.itemModelId}
                item={item}
                redirectUrl={props.redirectUrl}
                availableAmount={available}
                availableItems={availableItems}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default ItemResults;
