import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Hidden,
  TextField,
  Typography,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Feedback } from '../../models';
import { feedbackService } from '../../services';
import { selectUser } from '../../store/userSlice';
import useStyles from '../../styles';
import { createErrorNotification } from '../../services/helpers';

function FeedbackComponent(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const userEmail = useSelector(selectUser).email;
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);
  const [feedbackText, setFeedbackText] = useState<string>('');
  const [feedbackRating, setFeedbackRating] = useState<number | null>(null);

  const handleFeedbackOpen = (): void => setFeedbackOpen(true);
  const handleFeedbackClose = (): void => setFeedbackOpen(false);

  const sendFeedback = (): void => {
    handleFeedbackClose();

    feedbackService
      .createOne({
        sender: userEmail,
        content: feedbackText,
        rating: feedbackRating,
      })
      .then((feedback: Feedback | void) => {
        if (feedback) {
          setFeedbackText('');
        }
      });
  };

  return (
    <div className={classes.vMargin}>
      <Card className={classes.cfaNotification}>
        <Typography>{t('cfa.feedbackPrompt')}</Typography>
        {/* TODO: Hidden, really? For one property? */}
        <Hidden mdUp implementation="css">
          <Button
            className={classes.cfaButton}
            variant="contained"
            color={'secondary'}
            onClick={handleFeedbackOpen}
            fullWidth
          >
            {t('cfa.giveFeedback')}
          </Button>
        </Hidden>
        <Hidden smDown implementation="css">
          <Button className={classes.cfaButton} variant="contained" color={'secondary'} onClick={handleFeedbackOpen}>
            {t('cfa.giveFeedback')}
          </Button>
        </Hidden>
        <Dialog maxWidth="md" fullWidth open={feedbackOpen} onClose={handleFeedbackClose}>
          <DialogTitle>{t('views.feedback')}</DialogTitle>
          <DialogContent>
            <Rating
              name="feedback-rating"
              value={feedbackRating ?? null}
              onChange={(event, newValue) => setFeedbackRating(newValue)}
              size="large"
            />
            <TextField
              multiline
              variant="outlined"
              rows={3}
              fullWidth
              value={feedbackText}
              onChange={(event) => setFeedbackText(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={feedbackText.length === 0 && feedbackRating === null}
              onClick={() => {
                if (feedbackText.length > 1000) {
                  createErrorNotification(t('feedback.maxLength'));
                } else {
                  sendFeedback();
                }
              }}
            >
              {t('actions.send')}
            </Button>
            <Button onClick={handleFeedbackClose}>{t('actions.close')}</Button>
          </DialogActions>
        </Dialog>
      </Card>
    </div>
  );
}

export default FeedbackComponent;
