import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Drafts, Email } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Feedback } from '../../models';
import { feedbackService } from '../../services';
import useStyles from '../../styles';
import { Rating } from '@material-ui/lab';

function AdminFeedback(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const [fbRows, setFbRows] = useState<Feedback[]>([]);
  const [fbOpen, setFbOpen] = useState<number | undefined>(undefined);
  const [averageRating, setAverageRating] = useState<number | undefined>(undefined);

  useEffect(() => {
    feedbackService.fetchAll().then((fbs: Feedback[] | void) => {
      if (fbs) setFbRows(fbs);
    });
    feedbackService.getAverageRating().then((averageRating: number | void) => {
      if (averageRating) setAverageRating(averageRating);
    });
  }, []);

  const handleFbClose = (): void => setFbOpen(undefined);
  const handleFbOpen = (fbId: number, i: number): void => {
    // First, mark the message as read
    feedbackService.markAsRead(fbId).then((success: boolean | void) => {
      if (success) {
        const updatedFb = fbRows;
        updatedFb[i].opened = true;
        setFbRows(updatedFb);
      }
    });
    // Then, open the dialogue
    setFbOpen(i);
  };

  const getSenderName = (sender: string): string => (sender === '' ? t('general.guest') : sender);
  const feedbackRatingAmount = fbRows.filter((row) => row.rating !== null && row.rating !== 0).length;
  const feedbackTotalAmount = fbRows.length;

  return (
    <div>
      <div className={classes.flexRow}>
        <h1>{t('views.feedback')}</h1>
      </div>
      <div>
        <strong>{t('feedback.avgRating')}:</strong> {averageRating !== undefined ? averageRating : 'N/A'} / 5
      </div>
      <div>
        <strong>{t('feedback.ratingAmount')}:</strong> {feedbackRatingAmount} {t('general.kpl')}
      </div>
      <div style={{ marginBottom: '20px' }}>
        <strong>{t('feedback.totalAmount')}:</strong> {feedbackTotalAmount} {t('general.kpl')}
      </div>
      <Paper>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left">{t('general.sender')}</TableCell>
                <TableCell align="left">{t('general.date')}</TableCell>
                <TableCell align="left">{t('feedback.rating')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fbRows.map((fb: Feedback, i: number) => {
                const icon: JSX.Element = fb.opened ? <Drafts /> : <Email />;
                return (
                  <TableRow hover key={i} onClick={() => handleFbOpen(fb.feedbackId, i)}>
                    <TableCell>{icon}</TableCell>
                    <TableCell align="left">{getSenderName(fb.sender)}</TableCell>
                    <TableCell align="left">{moment(fb.date).format('DD.MM.yyyy HH:mm')}</TableCell>
                    <TableCell align="left">{<Rating name="feedback-rating" value={fb.rating} />}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Dialog open={fbOpen !== undefined} onClose={handleFbClose} transitionDuration={{ enter: 100, exit: 0 }}>
        <DialogTitle>{t('views.feedback')}</DialogTitle>
        <DialogContent className={classes.vFlex}>
          <em style={{ marginBottom: '20px' }}> {getSenderName(fbRows[fbOpen as number]?.sender ?? '')}</em>
          <div>
            <strong>Rating:</strong> {fbRows[fbOpen as number]?.rating ?? 'N/A'}
          </div>
          <div style={{ marginTop: '10px' }}>
            <strong>Feedback:</strong> {fbRows[fbOpen as number]?.content ?? ''}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFbClose}>{t('actions.close')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdminFeedback;
