import { ButtonBase, Grid, styled } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n';
import { ItemInstance, ReservationDTO } from '../../models';
import PaymentMethodsModel from '../../models/PaymentMethodsModel';
import { createErrorNotification } from '../../services/helpers';
import vismaPayService from '../../services/vismaPayService';
import useStyles from '../../styles';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

interface PaymentMethodsViewProps {
  disabled: boolean;
  instance: ItemInstance;
  startDate: string;
  endDate: string;
}

function PaymentMethodsView({ disabled, instance, startDate, endDate }: PaymentMethodsViewProps): JSX.Element {
  const { t } = useTranslation();
  const userId = useSelector((state: RootState) => state.user.id);
  const classes = useStyles();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodsModel[]>([] as PaymentMethodsModel[]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    vismaPayService.fetchPaymentMethods().then((r) => {
      if (r) {
        setPaymentMethods(r);
      }
    });
  }, []);

  const getPaymentToken = (selectedPaymentMethod: string) => {
    if (disabled) {
      return;
    }
    setLoading(true);
    const reservationDTO: ReservationDTO = {
      userId: userId!,
      itemInstanceId: instance.itemInstanceId,
      startDate,
      endDate,
    };
    vismaPayService.fetchPaymentToken(reservationDTO, selectedPaymentMethod).then((token) => {
      setLoading(false);
      if (!token || token.result !== 0) {
        createErrorNotification(i18n.t('notifications.error.paymentTokenFetchFailed'));
        return;
      }
      window.location.href = 'https://www.vismapay.com/pbwapi/token/' + token.token;
    });
  };
  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiImageBackdrop-root': {
        opacity: 0.15,
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
      },
      '& .MuiTypography-root': {
        border: '4px solid currentColor',
      },
    },
  }));
  return (
    <div>
      <h3>{t('payment.methods.select')}</h3>
      <Grid container spacing={2}>
        {paymentMethods?.map((method) => {
          if (method.group === 'creditinvoices') return null;
          return (
            <Grid item xs={6} md={4} lg={3} key={method.name}>
              <ImageButton
                focusRipple
                onClick={() => getPaymentToken(method.selected_value)}
                disabled={disabled || loading}
              >
                <img
                  src={`${method.img}?_v=${method.img_timestamp}`}
                  style={{ maxWidth: '100%' }}
                  className={[disabled && classes.imgGreyscale, !disabled].filter(Boolean).join(' ')}
                />
              </ImageButton>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default PaymentMethodsView;
