import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { HourglassFull, LockOpen, Person, Room, Send, SportsSoccer, HourglassEmpty } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { URLS } from '../../constants';
import { Log } from '../../models';
import { logService } from '../../services';
import useStyles from '../../styles';

interface TargetIcons {
  borrowing: JSX.Element;
  location: JSX.Element;
  item: JSX.Element;
  news: JSX.Element;
  user: JSX.Element;
  lockerDoor: JSX.Element;
  reservation: JSX.Element;
}

const targetIcons: TargetIcons = {
  borrowing: <HourglassFull />,
  location: <Room />,
  item: <SportsSoccer />,
  news: <Send />,
  user: <Person />,
  lockerDoor: <LockOpen />,
  reservation: <HourglassEmpty />,
};

interface TargetPaths {
  borrowing: string;
  location: string;
  item: string;
  itemModel: string;
  itemInstance: string;
  news: string;
  user: string;
}

const targetPaths: TargetPaths = {
  borrowing: URLS.ADMIN.ITEM,
  location: URLS.ADMIN.LOCATION,
  item: URLS.ADMIN.ITEM,
  itemModel: URLS.ADMIN.ITEM,
  itemInstance: URLS.ADMIN.ITEM,
  news: URLS.ADMIN.NEWSARTICLE,
  user: URLS.ADMIN.USER,
};

interface ActionColors {
  create: string;
  delete: string;
  failed: string;
  modify: string;
  borrow: string;
  return: string;
  open: string;
  reserve: string;
  reserve_fullfilled: string;
  reserve_removed: string;
}

const actionColors: ActionColors = {
  create: 'mediumaquamarine',
  delete: 'lightpink',
  failed: 'lightpink',
  modify: 'powderblue',
  borrow: 'bisque',
  return: 'plum',
  open: 'lightgreen',
  reserve: 'lightblue',
  reserve_fullfilled: 'green',
  reserve_removed: 'greenYellow',
};

interface TypeMapping {
  actionType: string;
  targetType: string;
}

const typeMapping: TypeMapping[] = [
  // First ones are reserved for later use
  { actionType: '', targetType: '' },
  { actionType: '', targetType: '' },
  { actionType: '', targetType: '' },
  { actionType: '', targetType: '' },
  { actionType: 'create', targetType: 'location' },
  { actionType: 'delete', targetType: 'location' },
  { actionType: 'modify', targetType: 'location' },
  { actionType: 'create', targetType: 'item' },
  { actionType: 'delete', targetType: 'item' },
  { actionType: 'modify', targetType: 'item' },
  { actionType: 'borrow', targetType: 'item' },
  { actionType: 'return', targetType: 'item' },
  { actionType: 'reserve', targetType: 'item' },
  { actionType: 'release', targetType: 'item' },
  { actionType: 'create', targetType: 'news' },
  { actionType: 'delete', targetType: 'news' },
  { actionType: 'modify', targetType: 'news' },
  { actionType: 'create', targetType: 'user' },
  { actionType: 'delete', targetType: 'user' },
  { actionType: 'modify', targetType: 'user' },
  { actionType: 'create', targetType: 'pageContent' },
  { actionType: 'modify', targetType: 'pageContent' },
  { actionType: 'delete', targetType: 'pageContent' },
  { actionType: 'create', targetType: 'itemModel' },
  { actionType: 'delete', targetType: 'itemModel' },
  { actionType: 'modify', targetType: 'itemModel' },
  { actionType: 'create', targetType: 'itemInstance' },
  { actionType: 'delete', targetType: 'itemInstance' },
  { actionType: 'modify', targetType: 'itemInstance' },
  { actionType: 'create', targetType: 'itemProblem' },
  { actionType: 'delete', targetType: 'itemProblem' },
  { actionType: 'paymentToken', targetType: 'itemInstance' },

  { actionType: 'failed', targetType: 'paymentReturn' },
  { actionType: 'failed', targetType: 'paymentReturn' },
  { actionType: 'failed', targetType: 'paymentReturn' },
  { actionType: 'return', targetType: 'paymentReturn' },
  { actionType: 'delete', targetType: 'user' },
  { actionType: 'update', targetType: 'user' },
  //{ actionType: 'create', targetType: 'order' },
  { actionType: 'open', targetType: 'lockerDoor' },
  { actionType: 'failed', targetType: 'lockerDoor' },
  { actionType: 'reserve_fullfilled', targetType: 'item' },
  { actionType: 'reserve_removed', targetType: 'item' },
  { actionType: 'reserve', targetType: 'item' },
];

function AdminLogs(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [logs, setLogs] = useState<Log[]>([]);

  useEffect(() => {
    logService.fetchAll().then((logsResponse: Log[] | void) => {
      if (logsResponse) setLogs(logsResponse);
    });
  }, []);

  return (
    <div>
      <div className={classes.flexRow}>
        <h1>{t('views.logs')}</h1>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell align="left">{t('general.date')}</TableCell>
              <TableCell align="right">{t('general.actor')}</TableCell>
              <TableCell align="right">{t('general.type')}</TableCell>
              <TableCell align="right">{t('general.target')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log: Log, i: number) => {
              const mapped = typeMapping[log.type];
              return (
                <TableRow key={i} style={{ backgroundColor: actionColors[mapped.actionType as keyof ActionColors] }}>
                  <TableCell>{targetIcons[mapped.targetType as keyof TargetIcons]}</TableCell>
                  <TableCell align="left">{moment(log.time).format('DD.MM.yyyy HH:mm')}</TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={() => {
                        history.push(`${URLS.ADMIN.USER}/${log.actorId}`);
                      }}
                    >
                      {log.actorEmail}
                    </Button>
                  </TableCell>

                  <TableCell align="right">{`${mapped.actionType} ${mapped.targetType}`}</TableCell>
                  <TableCell align="right">
                    <Button
                      // TODO: How to disable deleted items and other non-referrable targets?
                      onClick={() => {
                        history.push(`${targetPaths[mapped.targetType as keyof TargetPaths]}/${log.targetId}`);
                      }}
                    >
                      {log.targetObjectName}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default AdminLogs;
