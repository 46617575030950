import Calendar from 'react-calendar';
import { createErrorNotification } from '../../../services/helpers';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  InputLabel,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Done, InfoOutlined, LockOpen, Lock, Today } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment, { duration } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ConfirmationDialog } from '../../../components';
import FeedbackDialog from '../../../components/cfa/FeedbackDialog';
import ImageCarousel from '../../../components/item/ImageCarousel';
import PriceDisplay from '../../../components/item/PriceDisplay';
import { URLS } from '../../../constants';
import { getLocationById, secondsToDays } from '../../../helpers';
import {
  Borrowing,
  DoorCode,
  ImageData,
  ItemInstance,
  ItemModel,
  Location,
  LockerDoor,
  Reservation,
  ReservationDTO,
  ReservationTime,
  ServiceBreakTime,
} from '../../../models';
import {
  borrowingService,
  doorCodeService,
  imageService,
  itemService,
  reservationService,
  serviceBreakService,
} from '../../../services';
import lockerService from '../../../services/lockerService';
import { RootState } from '../../../store';
import { selectLocations } from '../../../store/locationsSlice';
import { addSkipForImage, selectSkipImageReload } from '../../../store/settingsSlice';
import { selectUser } from '../../../store/userSlice';
import useStyles from '../../../styles';
import { createImageData } from '../../../util';
import { DetailedItemModals } from './DetailedItemModals';
import { is } from 'date-fns/locale';
import i18n from '../../../i18n/i18n';
//import { set } from 'lodash';

function DetailedInstancePage(props: any): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(selectUser);
  // Only users should be able to access this view, empty value is never used
  const userId: string = user.id ?? '';
  const instanceId = props.match.params.instanceId;
  const startHour = 7;
  const endHour = 22;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [item, setItem] = useState<ItemModel>({} as ItemModel);
  const [borrowing, setBorrowing] = useState<Borrowing>({} as Borrowing);
  const [reservation, setReservation] = useState<Reservation>({} as Reservation);
  const [doorCode, setDoorCode] = useState<DoorCode>({} as DoorCode);
  const [lockerDoor, setLockerDoor] = useState<LockerDoor>({} as LockerDoor);

  const [loadingLockerDoorOpen, setLoadingLockerDoorOpen] = useState(false);
  const [loadingBorrowing, setLoadingBorrowing] = useState(true);
  const [allowBorrowing, setAllowBorrowing] = useState(false);
  const [loadingReservation, setLoadingReservation] = useState(true);
  const [instance, setInstance] = useState<ItemInstance>({} as ItemInstance);
  const [pickupInfoModalOpen, setPickupInfoModalOpen] = useState(false);
  const [returnInfoModalOpen, setReturnInfoModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModelOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);

  const [doorOpenConfirmationModalOpen, setDoorOpenConfirmationModalOpen] = useState(false);
  const [imageData, setImageData] = useState<ImageData | undefined>(undefined);
  const [imageData2, setImageData2] = useState<ImageData | undefined>(undefined);
  const [imageData3, setImageData3] = useState<ImageData | undefined>(undefined);
  const skipReload = useSelector((state) => selectSkipImageReload(state as RootState, item.itemModelId));
  const locations = useSelector(selectLocations);
  const [map, setMap] = useState(false);

  const [bookingDuration, setBookingDuration] = React.useState('0');
  const maxBookingTime = 14;

  const [showCalendarInfo, setShowCalendarInfo] = useState(false);
  const InfoIcon = (): JSX.Element => {
    return <InfoOutlined fontSize="medium" style={{ color: 'grey' }} />;
  };

  // Price is integer (in cents) in the database.
  // But is converted to euros in itemService.ts
  const priceBase = instance.price;
  const pricePerExtraDay = instance.pricePerExtraDay;
  const priceDiscount = (100 - instance.priceDiscountPercent) / 100; // for instance 0.79 if the sale percentage from UI is 21%
  let calculatedPrice: number;
  let calculatedDiscountPrice: number;
  // Used to match the amount of chosen days on calendar
  const parsedBookingDuration = parseInt(bookingDuration);

  // If booking duration is 1 above, calculate price with extra days
  if (parsedBookingDuration > 1) {
    calculatedPrice = parseFloat((priceBase + (parsedBookingDuration - 1) * pricePerExtraDay).toFixed(2));
    calculatedDiscountPrice = parseFloat((calculatedPrice * priceDiscount).toFixed(2));
  } else if (parsedBookingDuration === 1) {
    // Currently discounted price is not shown on UI but its still calculated here, if needed in the future
    calculatedPrice = priceBase;
    calculatedDiscountPrice = priceBase * priceDiscount;
  } else {
    // Zero if 0 days chosen
    calculatedPrice = 0;
    calculatedDiscountPrice = 0;
  }

  const handleFeedbackClose = (): void => setFeedbackOpen(false);
  const handleFeedbackOpen = (): void => setFeedbackOpen(true);

  const location = useMemo(() => {
    if (instance) {
      return getLocationById(locations, instance.locationId);
    }
    console.log('null');
    return null;
  }, [instance, locations]);
  // Coordinates for pick-up location
  const lat = location?.latitude;
  const lon = location?.longitude;

  const locationName = (locId: number): JSX.Element => {
    const loc = locations.find((location: Location) => location.locationId === locId);
    return (
      <div>
        <Typography variant={'subtitle2'}>{t('instance.location')}:</Typography>
        <Typography variant={'subtitle1'}>
          <strong>{loc?.name ?? ''}</strong>
        </Typography>
        <Typography variant={'subtitle1'}>
          <strong>{loc?.address ?? ''}</strong>
        </Typography>
        <Typography variant={'subtitle1'}>
          <strong>{loc?.moreInfo ?? ''}</strong>
        </Typography>
      </div>
    );
  };
  const returnItem = (): void => {
    // If the item is borrowed, return it and update item information
    if (borrowing)
      borrowingService.endBorrowing(userId, borrowing.borrowingId).then(() => {
        itemService.fetchOneInstance(instanceId, userId).then((itemResponse: ItemInstance | void) => {
          if (itemResponse) {
            setInstance(itemResponse);
          }
        });
      });
  };

  const loadReservation = async () => {
    setLoadingReservation(true);
    setAllowBorrowing(false);
    let userReservation: Reservation = {} as Reservation;
    const reservations: any = [];
    const dateNow = new Date();
    reservationService.fetchForItem(userId, instanceId).then((reservationResponse: Reservation[] | void) => {
      if (reservationResponse) {
        reservationResponse.map((reservation) => {
          reservations.push(reservation);
        });
        if (reservations.length === 0) {
          setLoadingReservation(false);
          return;
        }
        // Take the first reservation, if there are multiple
        userReservation = reservations[0];
        setReservation(userReservation);
        const startObj = new Date(userReservation.startDate);
        const endObj = new Date(userReservation.endDate);
        // Check if user has active reservation for this item
        if (dateNow >= startObj && dateNow <= endObj) {
          setAllowBorrowing(true);
        }
      }
    });
    setLoadingReservation(false);
    return;
  };

  const loadBorrowing = async () => {
    setLoadingBorrowing(true);
    let borrowing: void | Borrowing[];
    try {
      borrowing = await borrowingService
        .fetchForItem(userId, instance.itemInstanceId)
        .then((borrowing: Borrowing[] | void) => {
          if (borrowing) {
            borrowing.forEach((borr) => {
              setBorrowing(borr);
            });
          }
          return borrowing;
        });
      if (borrowing && borrowing?.[0] && borrowing?.[0]?.borrowingId) {
        const borrowingId = borrowing?.[0]?.borrowingId;
        await doorCodeService.fetchForBorrowing(borrowingId).then((doorCode: DoorCode | void) => {
          if (doorCode) {
            setDoorCode(doorCode);
          }
        });
        if (instance.lockerDoorId) {
          await lockerService.fetchForBorrowing(borrowingId).then((lockerDoor: LockerDoor | void) => {
            if (lockerDoor) {
              setLockerDoor(lockerDoor);
            }
          });
        }
      } else {
        setBorrowing({} as Borrowing);
        setDoorCode({} as DoorCode);
        setLockerDoor({} as LockerDoor);
      }
    } catch (e) {
      console.error(e);
    }
    setLoadingBorrowing(false);
    // return borrowing;
  };
  const loadItem = async () => {
    return itemService
      .fetchOneInstance(instanceId)
      .then((instance: ItemInstance | void) => {
        if (instance) {
          setInstance(instance);
          itemService.fetchOne(Number(instance.itemModelId), userId).then((itemResponse: ItemModel | void) => {
            if (itemResponse) {
              setItem(itemResponse);
            }
          });
        }
        return instance;
      })
      .then((instance) => {
        if (instance && instance.itemModelId) {
          imageService.fetchOne(instance.itemModelId, true, skipReload).then((imageBlob: Blob | void) => {
            createImageData(setImageData, imageBlob);
            if (!skipReload) dispatch(addSkipForImage(instance.itemModelId || 0));
          });
          imageService
            .fetchOneWithImageNumber(instance.itemModelId, 2, true, skipReload)
            .then((imageBlob: Blob | void) => {
              createImageData(setImageData2, imageBlob);
              if (!skipReload) dispatch(addSkipForImage(`${instance.itemModelId}_2`));
            });
          imageService
            .fetchOneWithImageNumber(instance.itemModelId, 2, true, skipReload)
            .then((imageBlob: Blob | void) => {
              createImageData(setImageData3, imageBlob);
              if (!skipReload) dispatch(addSkipForImage(`${instance.itemModelId}_3`));
            });
        }
      });
  };
  useEffect(() => {
    loadItem();
  }, [instanceId]);
  useEffect(() => {
    if (instance && instance.reservedByMe) {
      if (!userId) {
        setLoadingReservation(false);
        return;
      }
      loadReservation();
    } else {
      setLoadingReservation(false);
    }
  }, [instanceId, instance, userId]);

  useEffect(() => {
    // Fetch borrowing information for possible returning callback
    if (instance && instance.borrowedByMe) {
      /*intervalId = setInterval(() => {
        if (!userId) {
          setLoadingBorrowing(false);
          return;
        }
        clearInterval(intervalId);
        if (borrowing && borrowing.borrowingId) return false;
        // Wait for user id to be set
        loadBorrowing();
      }, 500);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };*/
      if (!userId) {
        setLoadingBorrowing(false);
        return;
      }
      loadBorrowing();
    } else {
      setLoadingBorrowing(false);
    }
  }, [instanceId, instance, userId]);

  const borrowStatus = (available: boolean, borrowedUntil: string | undefined): JSX.Element => {
    const date = moment(borrowedUntil).format('DD.MM.yyyy') ?? '';
    return (
      <>
        <Typography variant={'subtitle2'}>{t('item.status._')}:</Typography>
        {available ? (
          <div className={classes.greenChip}>{t('item.status.available')}</div>
        ) : (
          <div className={classes.redChip}>{`${t('item.status.unavailable')} (${date})`}</div>
        )}
      </>
    );
  };

  const openLocker = async () => {
    const _borrowing = structuredClone(borrowing);
    setLoadingLockerDoorOpen(true);
    const isPickup = !_borrowing.lockerOpened;
    const isReturn = _borrowing.lockerOpened;
    try {
      await borrowingService.openLocker(_borrowing.borrowingId);
      await loadBorrowing();
      await loadItem();
      setLoadingLockerDoorOpen(false);
      setDoorOpenConfirmationModalOpen(false);
      isPickup && setPickupInfoModalOpen(true);
      isReturn && setReturnInfoModalOpen(true);
    } catch (e) {
      console.error(e);
    }
    setLoadingLockerDoorOpen(false);
  };

  const getReservations = (): void => {
    const reserved: any = [];
    reservationService.fetchReservedDatesForItem(instanceId).then((reservationResponse: ReservationTime[] | void) => {
      if (reservationResponse) {
        reservationResponse.map((reservation) => {
          const startObj = new Date(reservation.startDate);
          const endObj = new Date(reservation.endDate);
          const reservedItem = {
            startDate: new Date(startObj.getFullYear(), startObj.getMonth(), startObj.getDate()),
            endDate: new Date(endObj.getFullYear(), endObj.getMonth(), endObj.getDate()),
          };
          reserved.push(reservedItem);
          updateDisabledDates(reservedItem.startDate, reservedItem.endDate);
        });
        setReserved(reserved);
      }
    });
  };

  const getServiceBreaks = (): void => {
    const serviceBreaks: any = [];
    serviceBreakService.fetchAllActiveDates().then((serviceBreakResponse: ServiceBreakTime[] | void) => {
      if (serviceBreakResponse) {
        serviceBreakResponse.map((serviceBreak) => {
          const startObj = new Date(serviceBreak.startDate);
          const endObj = new Date(serviceBreak.endDate);
          const serviceBreakItem = {
            startDate: new Date(startObj.getFullYear(), startObj.getMonth(), startObj.getDate()),
            endDate: new Date(endObj.getFullYear(), endObj.getMonth(), endObj.getDate()),
          };
          serviceBreaks.push(serviceBreakItem);
          updateServiceBreakDates(serviceBreakItem.startDate, serviceBreakItem.endDate);
        });
        setServiceBreaks(serviceBreaks);
      }
    });
  };

  const [disabledDays, setDisabledDays] = useState<Date[]>([]);
  const [serviceBreakDays, setServiceBreakDays] = useState<Date[]>([]);

  function addDays(currentDate: Date) {
    let date = new Date(currentDate);
    date.setDate(date.getDate() + 1);
    return date;
  }

  function updateDisabledDates(startDate: Date, endDate: Date) {
    let disabled = disabledDays;
    let currentDate: Date = startDate;
    while (currentDate <= endDate) {
      disabled.push(currentDate);
      currentDate = addDays(currentDate);
    }
    setDisabledDays(disabled);
  }

  function updateServiceBreakDates(startDate: Date, endDate: Date) {
    let serviceBreaks = serviceBreakDays;
    let currentDate: Date = startDate;
    while (currentDate <= endDate) {
      serviceBreaks.push(currentDate);
      currentDate = addDays(currentDate);
    }
    setServiceBreakDays(serviceBreaks);
  }

  function getDaysInRange(startDate: Date, endDate: Date) {
    let daysInRange = [];
    let currentDate: Date = startDate;
    while (currentDate < endDate) {
      daysInRange.push(currentDate);
      currentDate = addDays(currentDate);
    }
    return daysInRange;
  }

  const [reserved, setReserved] = useState<any>([]);
  const [serviceBreaks, setServiceBreaks] = useState<any>([]);

  type ValuePiece = Date | null;
  type Value = ValuePiece | [ValuePiece, ValuePiece];
  let [value, onChange] = useState<Value>();

  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  useEffect(() => {
    // Updates reservations
    getReservations();
    getServiceBreaks();
  }, [value]);

  function tileDisabled(date: Date): boolean {
    if (reserved.length > 0) {
      for (var res of reserved) {
        var from = res.startDate;
        var to = res.endDate;
        const result = date >= from && date <= to;
        if (result === true) {
          return true;
        }
      }
    }
    if (serviceBreaks.length > 0) {
      for (var sb of serviceBreaks) {
        var from = sb.startDate;
        var to = sb.endDate;
        const result = date >= from && date <= to;
        if (result === true) {
          return true;
        }
      }
    }
    return false;
  }

  function updateDateTime(date: Date, h: number, m: number, s: number) {
    date.setHours(h);
    date.setMinutes(m);
    date.setSeconds(s);
  }
  function isWithin30Days(selectedDate: Date): boolean {
    const now = new Date();
    const thirtyDaysFromNow = new Date(now.getTime() + 31 * 24 * 60 * 60 * 1000);
    return selectedDate <= thirtyDaysFromNow;
  }

  // Set fixed times for start and end dates
  function setFixedTimes(dates: Date[]) {
    let start = dates[0];
    let end = dates[1];
    let newDates = [];
    const newStartDate = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), startHour, 0, 0, 0));
    const newEndDate = new Date(Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), endHour, 0, 0, 0));
    newDates.push(newStartDate);
    newDates.push(newEndDate);

    return newDates;
  }

  function convertDaysToString(days: Date[]) {
    const daysString = [];
    for (var day of days) {
      daysString.push(day.toLocaleDateString());
    }
    return daysString;
  }

  function checkBookingDays(e: any) {
    const daysInRange = getDaysInRange(e[0], e[1]);
    const disabledDaysString = convertDaysToString(disabledDays);
    const daysInRangeString = convertDaysToString(daysInRange);

    for (var i = 0; i < daysInRange.length; i++) {
      if (disabledDaysString.includes(daysInRangeString[i])) {
        let newStartDate = e[0];
        let newEndDate = daysInRange[i - 1];
        updateDateTime(newEndDate, 23, 59, 59);
        e[0] = new Date(newStartDate.getFullYear(), newStartDate.getMonth(), newStartDate.getDate());
        e[1] = newEndDate;
        createErrorNotification(i18n.t('notifications.error.alreadyReserved'));
        break;
      }
    }
    return e;
  }

  const MyBookingCalendar = () => {
    const handleChange = (e: any) => {
      setDisabledDays([]);
      const event = checkBookingDays(e);
      const bookingDays: Array<Date> = event;
      const len = bookingDays.length;
      let range = 1;
      if (len > 1) {
        range = getDaysInRange(event[0], event[1]).length;
      }
      setBookingDuration(range.toString());
      if (range > maxBookingTime) {
        createErrorNotification(i18n.t('notifications.error.bookingTooLong', { maxBookingTime }));
        setSelectedDates([]);
        onChange(null);
        setBookingDuration('0');
      } else if (!isWithin30Days(event[1])) {
        createErrorNotification(i18n.t('notifications.error.notWithin30Days'));
        setSelectedDates([]);
        onChange(null);
        setBookingDuration('0');
      } else {
        onChange(event);
        setSelectedDates(event);
      }
    };
    return (
      <div>
        <Calendar
          onChange={handleChange}
          value={value}
          selectRange={true}
          tileDisabled={({ date }) => tileDisabled(date)}
          minDate={new Date()}
          minDetail="year"
          tileClassName={({ date }) => {
            var serviceBreaks = convertDaysToString(serviceBreakDays);
            if (serviceBreaks.includes(date.toLocaleDateString())) {
              return 'serviceDay';
            }
          }}
        />
        {selectedDates.length > 0 ? (
          <div className="text-center">
            <p>
              <span className="bold">{t('borrowing.bookingTime')} </span>
              {selectedDates[0].toLocaleDateString()} 7:00
              <span className="bold"> - </span>
              {selectedDates[1].toLocaleDateString()} 22:00
              <div>
                {parseInt(bookingDuration) <= maxBookingTime && (
                  <div>
                    {priceDiscount !== 1 && (
                      <p>
                        <p className={classes.pText}>
                          {t('borrowing.duration')} {parseInt(bookingDuration)} {t('item.borrowTimeUnit')}
                        </p>
                        <p className={classes.pText}>
                          {t('data.fields.discount')} {priceDiscount * 100 - 100}%
                        </p>
                        <p className={classes.pText}>
                          {t('data.fields.originalPrice')}{' '}
                          <span style={{ textDecoration: 'line-through' }}>{calculatedPrice} €</span>
                        </p>
                        <p className={classes.pText}>
                          {t('data.fields.newPrice')} {calculatedDiscountPrice} €
                        </p>
                      </p>
                    )}
                    {priceDiscount === 1 && (
                      <p>
                        <p className={classes.pText}>
                          {t('borrowing.duration')} {parseInt(bookingDuration)} {t('item.borrowTimeUnit')}
                        </p>
                        <p className={classes.pText}>
                          {t('data.fields.totalPrice')} {calculatedPrice} €
                        </p>
                      </p>
                    )}
                  </div>
                )}
              </div>
            </p>
          </div>
        ) : null}
      </div>
    );
  };
  function startBorrowing(): void {
    const borrowingDTO = {
      userId: userId,
      itemInstanceId: instance.itemInstanceId,
    };
    borrowingService.createBorrowing(borrowingDTO).then(() => {
      loadBorrowing();
      loadItem();
    });
  }

  return (
    <div>
      <div></div>
      {loadingBorrowing && <LinearProgress />}
      {!loadingReservation && !instance.borrowedByMe && instance.reservedByMe && (
        <Card className={classes.vMargin}>
          <CardContent>
            <Typography variant={'h6'} gutterBottom>
              {t('reservation.reservationStart')}
            </Typography>
            <Alert severity="info">{t('reservation.reservationStartInfo')}</Alert>
            <br />
            <Button
              color={'secondary'}
              variant="contained"
              className={classes.block}
              {...(!allowBorrowing && { disabled: true })}
              onClick={() => startBorrowing()}
            >
              {t('borrowing.activateBorrow')}
            </Button>
          </CardContent>
        </Card>
      )}
      {!loadingBorrowing && instance && !instance.available && instance.borrowedByMe && lockerDoor && lockerDoor.id && (
        <Card className={classes.vMargin}>
          <CardContent>
            <Typography variant={'h6'} gutterBottom>
              {!borrowing?.lockerOpened && t('locker.pickup')}
              {borrowing?.lockerOpened && t('locker.return')}
            </Typography>
            {!borrowing?.lockerOpened && <Alert severity="info">{t('locker.borrowInfo')}</Alert>}
            {borrowing?.lockerOpened && <Alert severity="info">{t('locker.returnInfo')}</Alert>}
          </CardContent>
          <CardContent>
            <Typography variant={'subtitle2'}>{t('locker.location')}: </Typography>
            <Typography variant={'subtitle1'}>
              <strong>{lockerDoor?.locker?.description}</strong>
            </Typography>
            <Typography variant={'subtitle2'}>{t('locker.door.door')}: </Typography>
            <Typography variant={'subtitle1'}>
              <strong>{lockerDoor?.door}</strong>
            </Typography>
            <br />
            <Button
              startIcon={<LockOpen />}
              color={'secondary'}
              variant="contained"
              className={classes.block}
              onClick={() => setDoorOpenConfirmationModalOpen(true)}
            >
              {t('locker.open')}
            </Button>
          </CardContent>
        </Card>
      )}
      {!loadingBorrowing &&
        instance &&
        !instance.available &&
        instance.borrowedByMe &&
        (!lockerDoor || !lockerDoor.id) && (
          <Card>
            <CardContent>
              <Typography variant={'h6'} gutterBottom>
                {t('borrowing.pickupOrReturn')}
              </Typography>
              <Alert severity="info"> {t('borrowing.pickupOrReturnText')}</Alert>
            </CardContent>
            <CardContent>
              {doorCode && doorCode.code && (
                <div>
                  <Typography variant={'subtitle2'}>{t('doorCode.header')}:</Typography>
                  <Typography variant={'subtitle1'}>
                    <span className={classes.pinCode}>{doorCode.code}</span>
                  </Typography>
                </div>
              )}
              <Button
                variant="contained"
                color="secondary"
                className={classes.block}
                startIcon={<Done />}
                onClick={() => {
                  setConfirmationModelOpen(true);
                }}
              >
                {t('item.actions.return')}
              </Button>
            </CardContent>
          </Card>
        )}
      <Card className={classes.vMargin}>
        <CardMedia>
          <ImageCarousel imageData={imageData} imageData2={imageData2} imageData3={imageData3} />
        </CardMedia>
        <CardHeader title={item && item.name} action={<DetailedItemModals instance={instance} />}></CardHeader>
        <CardContent>
          {item && (
            <div className={classes.vMargin}>
              <Typography variant={'subtitle1'} className={classes.lineBreak}>
                {item.description}
              </Typography>
            </div>
          )}
          {instance.size ? (
            <div>
              <Typography variant={'subtitle2'}>{t('instance.size')}:</Typography>
              <Typography variant={'subtitle1'}>
                <strong>{instance.size}</strong>
              </Typography>
            </div>
          ) : null}
          <div className={classes.vMargin}>
            {locationName(instance.locationId)}
            <Button
              variant="contained"
              color="secondary"
              className="link-button"
              onClick={() => {
                if (!map) {
                  setMap(true);
                } else {
                  setMap(false);
                }
              }}
            >
              {map ? t('actions.hideMap') : t('actions.showMap')}
            </Button>
            {map && (
              <iframe
                src={`https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`}
                width="600"
                height="450"
                allowFullScreen={false}
                loading="lazy"
                style={{ display: 'block', maxWidth: '100%' }}
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            )}
          </div>

          <PriceDisplay instance={instance} />
          {instance.priceDiscountPercent !== 100 && instance.priceDiscountPercent !== 0 && (
            <div className={classes.discountContainer}>
              <Typography className={classes.discountTag} variant={'subtitle1'}>
                {t('item.status.discountSave')}
              </Typography>
              <Typography className={classes.discountTag} variant={'subtitle1'}>
                -{instance.priceDiscountPercent}%
              </Typography>
            </div>
          )}

          <br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant={'h6'}> {t('borrowing.calendar')} </Typography>
            <IconButton onClick={calendarInfo}>{InfoIcon()}</IconButton>
          </div>
          <div>
            {showCalendarInfo ? (
              <div>
                <div>
                  <h4 className={classes.pText}>Varauksen tekeminen</h4>
                  <p className={classes.pText}>
                    Valitse vuokrauksen aloitus- ja lopetuspäivä klikkaamalla päivämäärä varauskalenterista.
                  </p>
                  <p className={classes.pText}>Aloitus- ja lopetuspäivä voivat olla myös samana päivänä.</p>
                </div>
                <div style={{ maxWidth: '350px' }}>
                  <h4 className={classes.pText}>Värien selitykset</h4>
                  <p className={classes.availableText}>Saatavilla</p>
                  <p className={classes.notAvailableText}>Ei saatavilla</p>
                  <p className={classes.selectedRangeText}>Valitsemasi ajanjakso</p>
                  <p className={classes.serviceBreakText}>Tuotetta ei mahdollista noutaa tai palauttaa tällöin</p>
                </div>
              </div>
            ) : null}
            {/* <Typography variant={'h6'}> Varauskalenteri </Typography> */}
            {/* <IconButton>{InfoIcon()}</IconButton> */}
            <div className={classes.bookingCalendar}>
              <MyBookingCalendar />
            </div>
          </div>
          {instance && (
            <Button
              variant="contained"
              color="primary"
              className={classes.blockBorrow}
              onClick={() => {
                if (selectedDates.length != 2) {
                  createErrorNotification(i18n.t('notifications.error.datesNotSelected'));
                } else {
                  if (location?.usePayments) {
                    const dates = setFixedTimes(selectedDates);
                    const startDate = dates[0].toISOString();
                    const endDate = dates[1].toISOString();
                    history.push({
                      pathname: URLS.EU.RESERVING,
                      state: { instance, item, startDate, endDate, price: calculatedDiscountPrice },
                    });
                  } else {
                    history.push({
                      pathname: URLS.EU.SCANNING,
                    });
                  }
                }
              }}
            >
              {t('item.actions.borrow')}
            </Button>
          )}
        </CardContent>
      </Card>

      <ConfirmationDialog
        title={t('locker.openConfirm')}
        open={doorOpenConfirmationModalOpen}
        onClose={() => setDoorOpenConfirmationModalOpen(false)}
        onOk={() => console.log}
        useOkButton={false}
      >
        <Typography variant={'subtitle1'} className={classes.vMargin}>
          {t('locker.openConfirmCustomerText')}
        </Typography>
        <br />
        <Typography variant={'subtitle2'}>{t('locker.location')}: </Typography>
        <Typography variant={'subtitle1'}>
          <strong>{lockerDoor?.locker?.description}</strong>
        </Typography>
        <Typography variant={'subtitle2'}>{t('locker.door.door')}: </Typography>
        <Typography variant={'subtitle1'}>
          <strong>{lockerDoor?.door}</strong>
        </Typography>
        <br />
        <Button
          startIcon={loadingLockerDoorOpen ? <CircularProgress size={26} /> : <LockOpen />}
          color={'secondary'}
          variant="contained"
          className={classes.block}
          onClick={async () => {
            await openLocker();
          }}
        >
          {t('locker.open')}
        </Button>
      </ConfirmationDialog>
      <ConfirmationDialog
        title={t('borrowing.pickupInfo')}
        open={pickupInfoModalOpen}
        onClose={() => setPickupInfoModalOpen(false)}
        onOk={() => {
          setPickupInfoModalOpen(false);
        }}
        useCancelButton={false}
        okButtonText={t('confirmationDialog._ok')}
      >
        {t('borrowing.pickupInfoText')}
      </ConfirmationDialog>
      <ConfirmationDialog
        title={t('borrowing.returnInfo')}
        open={returnInfoModalOpen}
        onClose={() => setReturnInfoModalOpen(false)}
        onOk={() => {
          setReturnInfoModalOpen(false);
        }}
        useCancelButton={false}
        okButtonText={t('confirmationDialog._ok')}
      >
        {t('borrowing.returnInfoText')}
      </ConfirmationDialog>
      <ConfirmationDialog
        title={t('borrowing.confirmReturned')}
        open={confirmationModalOpen}
        onClose={() => setConfirmationModelOpen(false)}
        onOk={() => {
          setConfirmationModelOpen(false);
          returnItem();
          handleFeedbackOpen();
        }}
      >
        {t('borrowing.confirmReturnedText')}
      </ConfirmationDialog>
      <FeedbackDialog open={feedbackOpen} onClose={handleFeedbackClose} />
    </div>
  );

  function calendarInfo(): void {
    if (!showCalendarInfo) {
      setShowCalendarInfo(true);
    } else {
      setShowCalendarInfo(false);
    }
  }
}

export default DetailedInstancePage;
