import { createStyles, makeStyles, Theme } from '@material-ui/core';
import './Calendar.css';

const drawerWidth = 240;
const successColor = '#25A79A';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appRoot: {
      display: 'flex',
      //margin: '0 60px',
      [theme.breakpoints.down('sm')]: {
        //margin: '0 10px',
      },
    },
    appContent: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    logo: {
      height: '32px',
    },
    heroContent: {
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      padding: '12px',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        '& .MuiTypography-h3': {
          fontSize: '2.2rem',
        },
        '& .MuiTypography-h5': {
          fontSize: '1.4rem',
          marginBottom: '1em',
        },
      },
    },
    heroSearch: {
      width: 'min(80%, 600px) !important',
      '& .Mui-focused': {
        backgroundColor: '#fff',
      },
    },
    heroSearchInput: {
      backgroundColor: '#fff',
    },
    heroTitle: {
      fontWeight: 700,
    },
    heroSubtitle: {
      marginTop: '1rem',
      marginBottom: '4rem',
    },
    heroSection: {
      width: 'calc(100vw - 257px)',
      height: 'min(450px, 80vh)' /* or any other height that you want */,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      marginLeft: '-16px' /* set negative margin to offset the content padding */,
      marginRight: '-16px' /* set negative margin to offset the content padding */,
      marginTop: '-16px',

      [theme.breakpoints.down('sm')]: {
        //margin: '0 10px',
        width: 'calc(100vw - 240px)',
      },
      [theme.breakpoints.down('xs')]: {
        //margin: '0 10px',
        width: '100vw',

        //marginRight: 0,
        //marginLeft: 0,
      },
    },
    frameworkNavLink: {
      color: 'white',
      padding: '0 5px',
    },
    frameworkDrawerPaper: {
      width: drawerWidth,
      background: `${theme.palette.primary.main} !important`,
    },
    frameworkDrawer: {
      // NOTE: Drawer was on top of appbar on production. Using z-indices seems
      //       to be the only way to fix it. Dunno why.
      zIndex: 610,
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    frameworkAppBar: {
      zIndex: 620,
      [theme.breakpoints.up('sm')]: {
        zIndex: theme.zIndex.drawer + 1,
        marginLeft: drawerWidth,
      },
    },
    frameworkMenuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
      color: 'white !important',
      // Magic constant that MUI adds as padding; let's remove it
      right: '4px',
    },
    profileCard: {
      padding: '30px',
      margin: '24px 0px',
    },
    profileDivider: {
      border: 'none',
      height: '1px',
      margin: '0',
      flexShrink: 0,
      backgroundColor: '#eeeeee',
    },
    borrowingCard: {
      padding: '30px',
      margin: '24px 0px',
    },
    borrowingDivider: {
      border: 'none',
      height: '1px',
      margin: '0',
      flexShrink: 0,
      backgroundColor: '#eeeeee',
    },
    itemName: {
      fontWeight: 700,
      lineHeight: 1.25,
      marginBottom: '0.5rem',
    },
    price: {
      color: theme.palette.error.main,
    },
    priceInt: {
      fontSize: '1.0rem',
      fontWeight: 700,
    },
    priceDec: {
      fontSize: '1.0rem',
      fontWeight: 700,
    },
    priceDisplay: {},
    imgGreyscale: {
      filter: 'grayscale(100%)',
    },
    discountContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    discountTag: {
      backgroundColor: '#ff5722', // orange
      fontSize: '1.0rem',
      fontWeight: 700,
      color: 'white', // or 'black' if preferred
      padding: '2px', // Add padding to make it look like a tag
    },
    cfaNotification: {
      padding: '10px',
      backgroundColor: '#B4E7FF !important',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    cfaButton: {
      margin: '2px 0',
      [theme.breakpoints.up('md')]: {
        flexShrink: 0,
      },
    },
    justifyCenter: {
      justifyContent: 'center',
    },
    alignCenter: {
      alignItems: 'center',
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    vFlex: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    hFlex: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    h100: {
      height: '100%',
    },
    fGrow: {
      flexGrow: 1,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    vMargin: {
      margin: '8px 0',
    },
    hMargin: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      '@media (max-width: 300px)': {
        marginLeft: -10,
        marginRight: 0,
      },
    },
    rMargin: {
      marginRight: theme.spacing(2),
    },
    animatedBar: {
      height: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
    },
    smallText: {
      fontSize: 'small',
      margin: '5px',
    },
    spacer: {
      flexGrow: 1,
    },
    itemContent: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      //maxWidth: '320px',
    },
    itemContentImageThumb: {
      backgroundColor: '#fff',
      //minWidth: '35%',
      width: '100%',
      minHeight: '192px',
      objectFit: 'contain',
      backgroundSize: 'contain',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minHeight: '192px',
      },
    },
    scanner: {
      maxWidth: '500px',
      maxHeight: '500px',
    },
    imageCard: {
      display: 'flex',
      maxHeight: '40vh',
      //height: '40vh',
    },
    imageFull: {
      width: '100%',
      height: 'auto',
      //objectFit: 'cover',
      objectFit: 'contain',
      backgroundColor: '#fff',
      borderRadius: '6px',
    },
    imageThumb: {
      backgroundColor: '#DDDDDD',
      width: '30%',
    },
    imageUpload: {
      display: 'none',
    },
    primary: {
      color: `${theme.palette.primary.main} !important`,
      fontWeight: 'bold',
    },
    drawerListItem: {
      color: 'white',
    },
    drawerListItemSelected: {
      color: theme.palette.primary.main,
      backgroundColor: `#faf3dd !important`,
    },
    white: {
      color: `#fff !important`,
    },
    green: {
      color: '#36575b',
      fontWeight: 'bold',
    },
    greenChip: {
      fontWeight: 'bold',
      color: '#25A79A',
    },
    orange: {
      color: '#ff5722',
      fontWeight: 'bold',
    },

    red: {
      color: '#f44336',
      fontWeight: 'bold',
    },
    redChip: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
    },
    component: {
      margin: '10px 0',
      padding: '10px',
    },
    card: {
      padding: '10px',
    },
    viewName: {
      fontWeight: 'bold',
      fontSize: 'xx-large',
    },
    componentName: {
      fontWeight: 'bold',
      fontSize: 'x-large',
    },
    gridItem: {
      width: '240px',
    },
    termsModalCloseButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    lineBreak: {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
    dropDown: {
      cursor: 'pointer',
      minWidth: '250px',
    },
    pinContainer: {
      marginTop: '1em',
      marginBottom: '2em',
      //backgroundColor: theme.palette.info.light,
      color: '#313131',
      paddingVertical: '1em',
      fontSize: '1em',
      display: 'inline-block',
    },
    pinCode: {
      color: '#313131',
      fontSize: '2em',
    },
    pointer: {
      cursor: 'pointer',
    },
    flexReverseRow: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    block: {
      width: '100%',
    },
    blockBorrow: {
      width: '100%',
      height: '40px',
      backgroundColor: successColor,
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    footerSection: {
      width: 'calc(100vw - 257px)',
      height: '200px' /* or any other height that you want */,
      marginLeft: '-16px' /* set negative margin to offset the content padding */,
      marginRight: '-16px' /* set negative margin to offset the content padding */,
      marginBottom: '-16px',
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        //margin: '0 10px',
        width: 'calc(100vw - 240px)',
        height: 'auto' /* or any other height that you want */,
      },
      [theme.breakpoints.down('xs')]: {
        //margin: '0 10px',
        width: '100vw',
        //marginRight: 0,
        //marginLeft: 0,
        height: 'auto' /* or any other height that you want */,
      },
    },
    bold: {
      fontWeight: 'bold',
    },
    footerTopMargin: {
      paddingTop: '32px',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    hideSmAndUp: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    showSmAndDown: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    flexSpaceBetween: {
      justifyContent: 'space-between',
    },
    bookingCalendar: {
      width: '100%',
      maxWidth: '400px',
      paddingBottom: '10px',
    },
    durationSelect: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '200px',
    },
    BookingOptions: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '300px',
      paddingBottom: '10px',
      paddingTop: '10px',
    },
    reserveBtn: {
      width: '100%',
      maxWidth: '350px',
      height: '30px',
      backgroundColor: 'white',
      border: '2px solid lightblue',
    },
    pText: {
      margin: '0px',
    },
    availableText: {
      margin: '0px',
      border: '1px solid black',
      borderBottom: '0px',
    },
    notAvailableText: {
      margin: '0px',
      backgroundColor: '#f0f0f0',
      color: '#1010104d',
      border: '1px solid black',
      borderBottom: '0px',
    },
    selectedRangeText: {
      margin: 0,
      backgroundColor: '#25A79A',
      color: 'white',
      border: '1px solid black',
      borderBottom: '0px',
    },
    serviceBreakText: {
      margin: 0,
      color: 'red',
      border: '1px solid black',
    },
  }),
);

export default useStyles;
