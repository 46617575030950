import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import { ServiceBreak, ServiceBreakDTO, ServiceBreakTime } from '../models';
import { checkStatus, createErrorNotification, createSuccessNotification } from './helpers';

// Fetch all coming servcebreak objects, only for admins
const fetchAllActiveObjects = async function (): Promise<ServiceBreak[] | void> {
  const model: string = i18n.t('data.models.servicebreak');
  const query = `${URLS.API}/servicebreak`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<ServiceBreak[]>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

// Fetch one servicebreak with id, only for admins
const fetchOne = async function (serviceBreakId: number): Promise<ServiceBreak | void> {
  const model: string = i18n.t('data.models.servicebreak');
  const query = `${URLS.API}/servicebreak/${serviceBreakId}`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<ServiceBreak>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

// Fetch coming dates for calendar
const fetchAllActiveDates = async function (): Promise<ServiceBreakTime[] | void> {
  const model: string = i18n.t('data.models.servicebreaktime');
  const query = `${URLS.API}/servicebreak/list`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<ServiceBreakTime[]>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

// Create new servicebreak, only for admins
const createServiceBreak = async function (serviceBreakDTO: ServiceBreakDTO): Promise<ServiceBreak | void> {
  const query = `${URLS.API}/servicebreak/started`;
  const init: RequestInit = {
    body: JSON.stringify(serviceBreakDTO),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const serviceBreak: Promise<ServiceBreak> = res.json() as Promise<ServiceBreak>;
    createSuccessNotification(i18n.t('notifications.success.serviceBreak'));
    return serviceBreak;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.serviceBreak'));
  }
};

// Remove servicebreak, only for admins
const removeServiceBreak = async function (serviceBreakId: number): Promise<ServiceBreak | void> {
  const query = `${URLS.API}/servicebreak/ended`;
  const init: RequestInit = {
    body: JSON.stringify({ serviceBreakId }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    console.warn(res);
    checkStatus(res);
    const serviceBreak: Promise<ServiceBreak> = res.json() as Promise<ServiceBreak>;
    createSuccessNotification(i18n.t('notifications.success.serviceBreakEnded'));
    return serviceBreak;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.serviceBreak'));
  }
};

export default {
  fetchAllActiveObjects,
  fetchOne,
  fetchAllActiveDates,
  createServiceBreak,
  removeServiceBreak,
};
