import React, { useEffect, useMemo, useState } from 'react';
import { ServiceBreak, ServiceBreakDTO } from '../../models';
import { serviceBreakService } from '../../services';
import i18n from '../../i18n/i18n';
import { createErrorNotification } from '../../services/helpers';

function ServiceBreakComponent(): JSX.Element {
  const [currentBreaks, setServiceBreaks] = useState<ServiceBreak[] | null>(null);

  useEffect(() => {
    reloadServiceBreaks();
  }, []);

  const [ServiceBreakDto, setServiceBreakDto] = useState<ServiceBreakDTO>({
    startDate: '',
    endDate: '',
  });

  const createServiceBreak: () => void = async () => {
    // End date can't be before start date
    if (ServiceBreakDto.startDate > ServiceBreakDto.endDate) {
      createErrorNotification(i18n.t('notifications.error.serviceBreakDate'));
      return;
    }

    try {
      await serviceBreakService.createServiceBreak(ServiceBreakDto);
      // Reload the list after creating a new service break
      reloadServiceBreaks();
    } catch (error) {
      console.error('Error creating service break:', error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setServiceBreakDto((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const reloadServiceBreaks = () => {
    serviceBreakService.fetchAllActiveObjects().then((serviceBreaksResponse: ServiceBreak[] | void) => {
      if (serviceBreaksResponse) {
        const activeServiceBreaks = serviceBreaksResponse.filter((breakObj) => !breakObj.removed);
        setServiceBreaks(activeServiceBreaks);
      }
    });
  };

  return (
    <div>
      <h1>{i18n.t('serviceBreak.serviceBreaks')}</h1>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
        <label htmlFor="startDate">{i18n.t('serviceBreak.startDate')}:</label>
        <input
          type="date"
          id="startDate"
          name="startDate"
          value={ServiceBreakDto.startDate}
          onChange={(event) => {
            handleInputChange(event);
            event.preventDefault();
          }}
        />

        <label htmlFor="endDate">{i18n.t('serviceBreak.endDate')}:</label>
        <input
          type="date"
          id="endDate"
          name="endDate"
          value={ServiceBreakDto.endDate}
          onChange={(event) => {
            handleInputChange(event);
            event.preventDefault();
          }}
        />
        <button onClick={createServiceBreak}>{i18n.t('serviceBreak.createBreak')}</button>
      </div>

      <h2>{i18n.t('serviceBreak.currentBreaks')}</h2>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <thead>
          <tr>
            <th style={{ padding: '8px', border: '1px solid #ddd', backgroundColor: '#f2f2f2' }}>
              {i18n.t('serviceBreak.serviceBreakId')}
            </th>
            <th style={{ padding: '8px', border: '1px solid #ddd', backgroundColor: '#f2f2f2' }}>
              {i18n.t('serviceBreak.startDate')}
            </th>
            <th style={{ padding: '8px', border: '1px solid #ddd', backgroundColor: '#f2f2f2' }}>
              {i18n.t('serviceBreak.endDate')}
            </th>
          </tr>
        </thead>
        <tbody>
          {currentBreaks?.map((breakObj) => (
            <tr key={breakObj.serviceBreakId}>
              <td style={{ padding: '8px', border: '1px solid #ddd' }}>{breakObj.serviceBreakId}</td>
              <td style={{ padding: '8px', border: '1px solid #ddd' }}>{breakObj.startDate}</td>
              <td style={{ padding: '8px', border: '1px solid #ddd' }}>{breakObj.endDate}</td>
              <td style={{ padding: '8px', border: '1px solid #ddd', minWidth: '50px', textAlign: 'center' }}>
                <button
                  style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
                  onClick={async () => {
                    await serviceBreakService.removeServiceBreak(breakObj.serviceBreakId);
                    reloadServiceBreaks();
                  }}
                >
                  {i18n.t('actions.delete')}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ServiceBreakComponent;
