import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Feedback } from '../../models';
import { feedbackService } from '../../services';
import { selectUser } from '../../store/userSlice';
import { createErrorNotification } from '../../services/helpers';

interface FeedbackDialogProps {
  open: boolean;
  onClose: () => void;
}

function FeedbackDialog({ open, onClose }: FeedbackDialogProps): JSX.Element {
  const { t } = useTranslation();
  const userEmail = useSelector(selectUser).email;
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);
  const [feedbackText, setFeedbackText] = useState<string>('');
  const [feedbackRating, setFeedbackRating] = useState<number | null>(null);

  const handleFeedbackClose = (): void => {
    setFeedbackText('');
    onClose();
  };

  const sendFeedback = (): void => {
    handleFeedbackClose();
    feedbackService
      .createOne({
        sender: userEmail,
        content: feedbackText,
        rating: feedbackRating,
      })
      .then((feedback: Feedback | void) => {
        if (feedback) {
          setFeedbackText('');
        }
      });
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleFeedbackClose}>
      <DialogTitle>{t('views.feedback')}</DialogTitle>
      <DialogContent>
        <Rating
          name="feedback-rating"
          value={feedbackRating ?? null}
          onChange={(event, newValue) => setFeedbackRating(newValue)}
          size="large"
        />
        <TextField
          multiline
          variant="outlined"
          rows={3}
          fullWidth
          value={feedbackText}
          onChange={(event) => setFeedbackText(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={feedbackText.length === 0 && feedbackRating === null}
          onClick={() => {
            if (feedbackText.length > 1000) {
              createErrorNotification(t('feedback.maxLength'));
            } else {
              sendFeedback();
            }
          }}
        >
          {t('actions.send')}
        </Button>
        <Button onClick={handleFeedbackClose}>{t('actions.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default FeedbackDialog;
