import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import { NewsArticle, NewsArticleDTO } from '../models';
import { checkStatus, createErrorNotification, createSuccessNotification } from './helpers';

const fetchAll = async function (): Promise<NewsArticle[] | void> {
  const model: string = i18n.t('data.models.newsarticle');
  const query = `${URLS.API}/news`;

  try {
    const res = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<NewsArticle[]>;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const fetchOne = async function (id: number): Promise<NewsArticle | void> {
  const model: string = i18n.t('data.models.newsarticle');
  const query = `${URLS.API}/news/${id}`;
  try {
    const res = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<NewsArticle>;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const createOne = async function (userId: string, newsArticleDto: NewsArticleDTO): Promise<NewsArticle | void> {
  const model: string = i18n.t('data.models.newsarticle');
  const query = `${URLS.API}/news?userId=${userId}`;
  const init: RequestInit = {
    body: JSON.stringify(newsArticleDto),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const newsArticle: Promise<NewsArticle> = res.json() as Promise<NewsArticle>;
    createSuccessNotification(i18n.t('notifications.success.create', { model }));
    return newsArticle;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.create', { model }));
  }
};

const updateOne = async function (
  userId: string,
  newsarticleDto: NewsArticleDTO,
  newsarticleId: number,
): Promise<NewsArticle | void> {
  const model: string = i18n.t('data.models.newsarticle');
  const query = `${URLS.API}/news/${newsarticleId}?userId=${userId}`;
  const init: RequestInit = {
    body: JSON.stringify(newsarticleDto),
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const newsarticle: Promise<NewsArticle> = res.json() as Promise<NewsArticle>;
    createSuccessNotification(i18n.t('notifications.success.update', { model }));
    return newsarticle;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.update'));
  }
};

const deleteOne = async function (userId: string, newsarticleId: number): Promise<NewsArticle[] | void> {
  const model: string = i18n.t('data.models.newsarticle');
  const query = `${URLS.API}/news/${newsarticleId}?userId=${userId}`;
  const init: RequestInit = { method: 'DELETE' };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const newsarticle: Promise<NewsArticle[]> = res.json() as Promise<NewsArticle[]>;
    createSuccessNotification(i18n.t('notifications.success.delete', { model }));
    return newsarticle;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.delete', { model }));
  }
};

export default { fetchAll, fetchOne, createOne, updateOne, deleteOne };
