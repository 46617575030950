import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { CropFree, Done, Favorite, FavoriteBorder, HourglassFull } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InstanceComponent } from '../../../components';
import ImageCarousel from '../../../components/item/ImageCarousel';
import { URLS } from '../../../constants';
import { Borrowing, ImageData, ItemInstance, ItemModel, Like, Location } from '../../../models';
import { borrowingService, imageService, itemService, likeService, locationService } from '../../../services';
import { RootState } from '../../../store';
import { selectFilters } from '../../../store/searchSlice';
import { addSkipForImage, selectSkipImageReload } from '../../../store/settingsSlice';
import { selectUser } from '../../../store/userSlice';
import useStyles from '../../../styles';
import theme from '../../../theme';
import { createImageData } from '../../../util';
import { DetailedItemModals } from './DetailedItemModals';

const secondsToDays = (seconds: number): number => {
  const duration = moment.duration(seconds * 1000);
  return duration.asDays();
};

function DetailedItemPage(props: any): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(selectUser);
  // Only users should be able to access this view, empty value is never used
  const userId: string = user.id ?? '';
  const itemId = props.match.params.itemId;
  const dispatch = useDispatch();
  const classes = useStyles();
  const skipReload = useSelector((state) => selectSkipImageReload(state as RootState, itemId));
  const filters = useSelector((state) => selectFilters(state as RootState));
  const [item, setItem] = useState<ItemModel>({} as ItemModel);
  const [like, setLike] = useState<Like | undefined>(undefined);
  const [locations, setLocations] = useState<Location[]>([]);
  const [imageData, setImageData] = useState<ImageData | undefined>(undefined);
  const [imageData2, setImageData2] = useState<ImageData | undefined>(undefined);
  const [imageData3, setImageData3] = useState<ImageData | undefined>(undefined);
  const [borrowing, setBorrowing] = useState<Borrowing>({} as Borrowing);
  const [instances, setInstances] = useState<ItemInstance[]>([]);
  const [instance, setInstance] = useState<ItemInstance>({} as ItemInstance);

  const toggleLike = (): void => {
    like === undefined
      ? likeService.updateOne(userId, itemId).then((like: Like | void) => {
          if (like) {
            setLike(like);
          }
        })
      : likeService.deleteOne(userId, itemId).then(() => setLike(undefined));
  };

  const returnItem = (): void => {
    // If the item is borrowed, return it and update item information
    if (borrowing)
      borrowingService.endBorrowing(userId, borrowing.borrowingId).then(() => {
        itemService.fetchOne(itemId, userId).then((itemResponse: ItemModel | void) => {
          if (itemResponse) {
            setItem(itemResponse);
          }
        });
        itemService.fetchInstancesForOneModel(itemId).then((instances: ItemInstance[] | void) => {
          if (instances && instances.length > 0 && filters.ownLocation) {
            instances = instances.filter((instance) => instance.locationId === user.defaultLocationId);
          }
          if (instances && instances.length > 1) {
            setInstances(instances);
          } else if (instances && instances.length === 1) {
            setInstances(instances);
            instances.forEach((instance) => setInstance(instance));
          }
        });
      });
  };

  const createButton = (textKey: string, icon: JSX.Element, callback?: any): JSX.Element => (
    <Button variant="contained" color="secondary" startIcon={icon} onClick={callback}>
      {t(textKey)}
    </Button>
  );

  const statusAction = (instance: ItemInstance): JSX.Element | undefined => {
    if (instance?.available) {
      return createButton('item.actions.borrow', <CropFree />, () => {
        history.push(URLS.EU.SCANNING);
      });
    } else {
      return instance?.borrowedByMe
        ? createButton('item.actions.return', <Done />, returnItem)
        : createButton('item.actions.reserve', <HourglassFull />);
    }
  };

  const statusActionMany = (): JSX.Element | undefined => {
    return createButton('item.actions.borrow', <CropFree />, () => {
      history.push(URLS.EU.SCANNING);
    });
  };

  const likeIcon = (): JSX.Element => {
    return like !== undefined ? (
      <Favorite fontSize="large" style={{ color: 'red' }} />
    ) : (
      <FavoriteBorder fontSize="large" style={{ color: 'red' }} />
    );
  };

  useEffect(() => {
    itemService.fetchOne(itemId, userId).then((itemResponse: ItemModel | void) => {
      if (itemResponse) {
        setItem(itemResponse);
      }
    });
    imageService.fetchOne(itemId, true, skipReload).then((imageBlob: Blob | void) => {
      createImageData(setImageData, imageBlob);
      if (!skipReload) dispatch(addSkipForImage(itemId));
    });
    imageService.fetchOneWithImageNumber(itemId, 2, true, skipReload).then((imageBlob: Blob | void) => {
      createImageData(setImageData2, imageBlob);
      if (!skipReload) dispatch(addSkipForImage(`${itemId}_2`));
    });
    imageService.fetchOneWithImageNumber(itemId, 3, true, skipReload).then((imageBlob: Blob | void) => {
      createImageData(setImageData3, imageBlob);
      if (!skipReload) dispatch(addSkipForImage(`${itemId}_3`));
    });
    likeService.fetchOne(userId, itemId).then((likeResponse?: Like) => {
      if (likeResponse && likeResponse.likedById !== '') setLike(likeResponse);
    });
  }, []);

  useEffect(() => {
    itemService.fetchInstancesForOneModel(itemId).then((instances: ItemInstance[] | void) => {
      if (instances && instances.length > 0 && filters.ownLocation) {
        instances = instances.filter((instance) => instance.locationId === user.defaultLocationId);
      }
      if (instances && instances.length > 1) {
        setInstances(instances);
      } else if (instances && instances.length === 1) {
        setInstances(instances);
        instances.forEach((instance) => setInstance(instance));
      }
    });
    locationService.fetchAll().then((locations: Location[] | void) => {
      if (locations) {
        setLocations(locations);
      }
    });
  }, [itemId, item]);

  useEffect(() => {
    // Fetch borrowing information for possible returning callback
    if (instance && instance.borrowedByMe) {
      borrowingService.fetchForItem(userId, instance.itemInstanceId).then((borrowing: Borrowing[] | void) => {
        if (borrowing) {
          borrowing.forEach((borr) => {
            setBorrowing(borr);
          });
        }
      });
    }
  }, [itemId, instance, userId, dispatch]);

  const borrowStatus = (available: boolean, borrowedUntil: string | undefined): JSX.Element => {
    const date = moment(borrowedUntil).format('DD.MM.yyyy') ?? '';
    return available ? (
      <p className={classes.green}>{t('item.status.available')}</p>
    ) : (
      <p className={classes.red}>{`${t('item.status.unavailable')} (${date})`}</p>
    );
  };

  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Card className={classes.vMargin}>
        {item && imageData?.url && (
          <CardMedia>
            <ImageCarousel imageData={imageData} imageData2={imageData2} imageData3={imageData3} />
          </CardMedia>
        )}
        <CardHeader
          title={item && item.name}
          action={
            <div className={[classes.hFlex, classes.hideSmAndUp].join(' ')}>
              {instances.length === 1 ? <DetailedItemModals instance={instances[0]} /> : undefined}
              {instances.length > 1 ? <DetailedItemModals instance={{} as ItemInstance} /> : undefined}
              <IconButton onClick={toggleLike}>{likeIcon()}</IconButton>
            </div>
          }
        ></CardHeader>
        <CardContent>
          {item && (
            <div className={classes.vMargin}>
              <Typography variant={'subtitle1'} className={classes.lineBreak}>
                {item.description}
              </Typography>
            </div>
          )}
        </CardContent>
        {isBelowSm && (
          <CardActions disableSpacing>
            <div className={classes.spacer}></div>
            {instances.length === 1 ? <DetailedItemModals instance={instances[0]} /> : undefined}
            {instances.length > 1 ? <DetailedItemModals instance={{} as ItemInstance} /> : undefined}
            <IconButton onClick={toggleLike}>{likeIcon()}</IconButton>
          </CardActions>
        )}
      </Card>
      {instances.length > 0 && (
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6}>
                <Typography variant={'subtitle1'} style={{ marginBottom: '1em' }}>
                  <strong>
                    {t('search.results.available')}
                    {instances.length}
                  </strong>
                </Typography>
                <Grid container spacing={3}>
                  {instances.map((instance: ItemInstance, index) => (
                    <Grid item xs={12} md={6}>
                      <InstanceComponent
                        key={index}
                        instance={instance}
                        item={item}
                        showMaxBorrowTime={false}
                        redirectUrl={`${URLS.EU.INSTANCE_NOQUERY}`}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

export default DetailedItemPage;
