import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import { Reservation, ReservationDTO, ReservationTime } from '../models';
import { checkStatus, createErrorNotification, createSuccessNotification } from './helpers';

/**
 * Fetch all reservations for a specific user
 * @param userId User ID
 * @returns List of Reservation objects or void (see ../models/Reservation.ts)
 */
const fetchAll = async function (userId: string): Promise<Reservation[] | void> {
  const model: string = i18n.t('data.models.reservation');
  const query = `${URLS.API}/reservation?userId=${userId}`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Reservation[]>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

/**
 * Fetch all active reservations, Only for admins
 * @returns List of Reservation objects or void (see ../models/Reservation.ts)
 */
const fetchAllActive = async function (): Promise<Reservation[] | void> {
  const model: string = i18n.t('data.models.reservation');
  const query = `${URLS.API}/reservation?Ended=false`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Reservation[]>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};
/**
 * Fetch all reserved dates for a specific item
 * @param itemInstanceId Item instance ID
 * @returns List of ReservationTime objects or void (see ../models/ReservationTime.ts)
 *          Empty list if no reservations
 */
const fetchReservedDatesForItem = async function (itemInstanceId: number): Promise<ReservationTime[] | void> {
  const model: string = i18n.t('data.models.reservationtime');
  const query = `${URLS.API}/reservation/list?itemInstanceId=${itemInstanceId}`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<ReservationTime[]>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};
/**
 * Fetches reservation for specific user for a specific item
 * @param userId User ID
 * @param itemInstanceId Item instance ID
 * @returns List of Reservation objects or void (see ../models/Reservation.ts)
 */
const fetchForItem = async function (userId: string, itemInstanceId: number): Promise<Reservation[] | void> {
  const model: string = i18n.t('data.models.reservation');
  const query = `${URLS.API}/reservation?userId=${userId}&itemInstanceId=${itemInstanceId}&ended=false&removed=false`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Reservation[]>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

/**
 * Fetches reservation for a specific item, Only for admins
 * @param itemInstanceId Item instance ID
 * @returns List of Reservation objects or void (see ../models/Reservation.ts)
 */
const fetchOne = async function (itemInstanceId: number): Promise<Reservation[] | void> {
  const model: string = i18n.t('data.models.reservation');
  const query = `${URLS.API}/reservation?itemInstanceId=${itemInstanceId}&ended=false`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Reservation[]>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

/**
 * Creates a reservation for current user
 * @param reservationDTO Reservation DTO object (see ../models/ReservationDTO.ts)
 * @returns Reservation object or void (see ../models/Reservation.ts)
 */
const createReservation = async function (reservationDTO: ReservationDTO): Promise<Reservation | void> {
  const query = `${URLS.API}/reservation/started`;
  const init: RequestInit = {
    body: JSON.stringify(reservationDTO),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const reservation: Promise<Reservation> = res.json() as Promise<Reservation>;
    createSuccessNotification(i18n.t('notifications.success.reservation'));
    return reservation;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.reservation'));
  }
};

/**
 * Ends an active reservation
 * @param userId User ID
 * @param reservationId Reservation ID
 * @returns Reservation object or void (see ../models/Reservation.ts)
 */
const endReservation = async function (userId: string, reservationId: number): Promise<Reservation | void> {
  const query = `${URLS.API}/reservation/ended`;
  const init: RequestInit = {
    body: JSON.stringify({ userId, reservationId }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    console.warn(res);
    checkStatus(res);
    const reservation: Promise<Reservation> = res.json() as Promise<Reservation>;
    createSuccessNotification(i18n.t('notifications.success.reservationEnded'));
    return reservation;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.reservation'));
  }
};

const removeReservation = async function (userId: string, reservationId: number): Promise<Reservation | void> {
  const query = `${URLS.API}/reservation/removed`;
  const init: RequestInit = {
    body: JSON.stringify({ userId, reservationId }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    console.warn(res);
    checkStatus(res);
    const reservation: Promise<Reservation> = res.json() as Promise<Reservation>;
    createSuccessNotification(i18n.t('notifications.success.reservationEnded'));
    return reservation;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.reservation'));
  }
};

export default {
  fetchAll,
  fetchAllActive,
  fetchForItem,
  fetchReservedDatesForItem,
  fetchOne,
  createReservation,
  endReservation,
  removeReservation,
};
