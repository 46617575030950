import { VALIDATION } from '../../constants';
import { ItemInstanceDTO, ItemModelDTO, LocationDTO, NewsArticleDTO, UserDTO } from '../../models';

function errorInText(text: string, length: number): boolean {
  return text?.length < length;
}
function errorInLength(text: string, length: number): boolean {
  return text?.length > length;
}

function errorInNumber(number: number): boolean {
  return number <= 0;
}

function errorInCoordinate(number: string): boolean {
  return (parseFloat(number)<= 0 ||
          parseFloat(number) > 1000 ||
          String(number)
          .split('')
          .filter((n) => n === '.').length > 1 || // Has more than one dots
          isNaN(+number) ||
          number.length < 1
          );
}

function errorInPrice(number: string): boolean {
  const regex = /^\d+(?:\.?\d{0,2}?)$/;
  /*console.log(
    parseInt(number) < 0,
    String(number)
      .split('')
      .filter((n) => n === '.').length > 1,
    !regex.test(String(number)),
    isNaN(+number)
  );*/
  return (
    parseInt(number) < 0 ||
    String(number)
      .split('')
      .filter((n) => n === '.').length > 1 || // Has more than one dots
    !regex.test(String(number)) ||
    isNaN(+number)
  );
}
function errorInDiscountPercent(number: string): boolean {
  const regex = /^(?:\d|[1-9]\d|100)$/;
  return (
    parseInt(number) < 0 ||
    parseInt(number) > 100 ||
    String(number)
      .split('')
      .filter((n) => n === '.').length > 0 || // Has dots
    !regex.test(String(number)) ||
    isNaN(+number)
  );
}

function errorInItem(itemModelDto: ItemModelDTO): boolean {
  const errors: boolean[] = [
    errorInText(itemModelDto.name, VALIDATION.ITEM.NAME),
    errorInText(itemModelDto.description, VALIDATION.ITEM.DESCRIPTION),
    errorInNumber(itemModelDto.maxBorrowingTime),
  ];

  return errors.includes(true);
}

function errorInInstance(itemInstanceDto: ItemInstanceDTO): boolean {
  const errors: boolean[] = [
    errorInText(itemInstanceDto.size, VALIDATION.INSTANCE.SIZE),
    errorInNumber(itemInstanceDto.locationId),
  ];

  return errors.includes(true);
}

function errorInLocation(locationDto: LocationDTO): boolean {
  const errors: boolean[] = [
    errorInText(locationDto.name, VALIDATION.LOCATION.NAME),
    errorInText(locationDto.address, VALIDATION.LOCATION.ADDRESS),
    errorInCoordinate(String(locationDto.latitude)),
    errorInCoordinate(String(locationDto.longitude)),
    errorInLength(locationDto.moreInfo, VALIDATION.LOCATION.MOREINFO),
  ];

  return errors.includes(true);
}

function errorInNewsArticle(newsArticleDto: NewsArticleDTO): boolean {
  const errors: boolean[] = [
    errorInText(newsArticleDto.author, VALIDATION.NEWS.AUTHOR),
    errorInText(newsArticleDto.heading, VALIDATION.NEWS.HEADING),
    errorInText(newsArticleDto.body, VALIDATION.NEWS.BODY),
  ];

  return errors.includes(true);
}

function errorInImage(imageBlob: Blob | undefined): boolean {
  return imageBlob === undefined;
}

function errorInUser(userDto: UserDTO): boolean {
  const errors: boolean[] = [
    errorInText(userDto.firstName, VALIDATION.USER.NAME),
    errorInText(userDto.lastName, VALIDATION.USER.NAME),
    errorInText(userDto.email, VALIDATION.USER.NAME),
  ];

  return errors.includes(true);
}

function errorUnableToRemoveItemModel(itemModelDto: ItemModelDTO) {
  errorInText(itemModelDto.name, VALIDATION.ITEM.NAME);
}

export {
  errorInText,
  errorInLength,
  errorInNumber,
  errorInPrice,
  errorInDiscountPercent,
  errorInItem,
  errorInLocation,
  errorInNewsArticle,
  errorInInstance,
  errorInImage,
  errorInUser,
  errorUnableToRemoveItemModel,
  errorInCoordinate
};
