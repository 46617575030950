import { FormControlLabel, FormGroup, Switch, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VALIDATION } from '../../constants';
import { LocationDTO } from '../../models';
import useStyles from '../../styles';
import { errorInNumber, errorInText, errorInCoordinate, errorInLength } from './validation';


const replaceCoordinateChars = (value: string) => {
  const regex = /[^0-9\.]*/gi;
  return value.replace(',', '.').replace(regex, '');
};


interface LocationFormProps {
  dto: LocationDTO;
  setDto: any;
  initializeWithDto: boolean;
}

interface LocationValidationErrors {
  name: boolean;
  address: boolean;
  moreInfo: boolean;
  latitude: boolean;
  longitude: boolean;
}

const initialValidationErrors: LocationValidationErrors = {
  name: false,
  address: false,
  moreInfo: false,
  latitude: false,
  longitude: false,
};

function LocationForm(props: LocationFormProps): JSX.Element {
  const classes = useStyles();
  const [errors, setErrors]: [LocationValidationErrors, any] = useState(initialValidationErrors);
  const { t } = useTranslation();

  return (
    <div>
      <TextField
        className={classes.vMargin}
        label={t('data.fields.name')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.name : undefined}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, name: event.target.value });
        }}
        error={errors.name}
        helperText={t('validation.mustHaveLength', { length: VALIDATION.LOCATION.NAME })}
        onBlur={(event: any): void =>
          setErrors({ ...errors, name: errorInText(event.target.value, VALIDATION.LOCATION.NAME) })
        }
      />
      <TextField
        className={classes.vMargin}
        label={t('data.fields.address')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.address : undefined}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, address: event.target.value });
        }}
        error={errors.address}
        helperText={t('validation.mustHaveLength', { length: VALIDATION.LOCATION.ADDRESS })}
        onBlur={(event: any): void =>
          setErrors({ ...errors, address: errorInText(event.target.value, VALIDATION.LOCATION.ADDRESS) })
        }
      />
      <TextField
        className={classes.vMargin}
        label={t('data.fields.moreInfo')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.moreInfo : undefined}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, moreInfo: event.target.value });
        }}
        error={errors.moreInfo}
        helperText={t('validation.maxLength', { length: VALIDATION.LOCATION.MOREINFO })}
        onKeyUp={(event: any): void =>
          setErrors({ ...errors, moreInfo: errorInLength(event.target.value, VALIDATION.LOCATION.MOREINFO) })
        }
      />

      <TextField
        className={classes.vMargin}
        label={t('data.fields.latitude')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.latitude : undefined}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ 
            ...props.dto, 
            latitude: replaceCoordinateChars(event.target.value),
          });

        }}
        helperText={t('validation.coordinate')}
        error={errors.latitude}
        onBlur={(event: any): void => setErrors({ ...errors, latitude: errorInCoordinate(event.target.value) })}

      />
      <TextField
        className={classes.vMargin}
        label={t('data.fields.longitude')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.longitude : undefined}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ 
            ...props.dto, 
            longitude: replaceCoordinateChars(event.target.value),
          });
        }}
        helperText={t('validation.coordinate')}
        error={errors.longitude}
        onBlur={(event: any): void => setErrors({ ...errors, longitude: errorInCoordinate(event.target.value) })}

      />
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={props.dto.usePayments}
              onChange={(event, checked) => {
                props.setDto({ ...props.dto, usePayments: checked });
              }}
            />
          }
          label={t('data.fields.usePayments')}
        ></FormControlLabel>
      </FormGroup>
    </div>
  );
}

export default LocationForm;
