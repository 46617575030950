import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getLocationById } from '../../helpers';
import { ImageData, ItemInstance, ItemModel } from '../../models';
import { imageService } from '../../services';
import { RootState } from '../../store';
import { selectLocations } from '../../store/locationsSlice';
import { selectFilters } from '../../store/searchSlice';
import { addSkipForImage, selectSkipImageReload } from '../../store/settingsSlice';
import { selectUser } from '../../store/userSlice';
import useStyles from '../../styles';
import { createImageData, dummySvg } from '../../util';
import PriceDisplay from './PriceDisplay';

interface ItemComponentProps {
  item: ItemModel;
  instance?: ItemInstance;
  availableAmount?: number;
  redirectUrl?: string;
  availableItems?: ItemInstance[];
}

function ItemComponent(props: ItemComponentProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const itemId = props.item.itemModelId;
  const skipReload = useSelector((state) => selectSkipImageReload(state as RootState, itemId));
  const [imageData, setImageData] = useState<ImageData | undefined>(undefined);
  const locations = useSelector(selectLocations);

  const userLocation = useSelector(selectUser)?.defaultLocationId;

  const filters = useSelector(selectFilters);

  const location = useMemo(() => {
    if (props.instance) {
      return getLocationById(locations, props.instance.locationId);
    }
    return null;
  }, [props.instance, locations]);

  useEffect(() => {
    // Prevent trying to fetch the same data multiple times
    if (!imageData) {
      // Fetch image's thumbnail and save it
      imageService.fetchOne(itemId, false, skipReload).then((imageBlob: Blob | void) => {
        createImageData(setImageData, imageBlob);
        // If the image was loaded now, make it use the cache in the future
        if (!skipReload) dispatch(addSkipForImage(itemId));
      });
    }
  }, [dispatch, imageData, itemId, skipReload]);

  const borrowStatus = (availableAmount: number, availableItems?: ItemInstance[]): JSX.Element => {
    if (availableAmount && availableAmount === 1) {
      return <div className={classes.greenChip}>{t('item.status.available')}</div>;
    } else if (availableAmount && availableAmount > 1) {
      return <div className={classes.greenChip}>{t('item.status.available')}</div>;
    } /* else if (availableItems && availableItems.length > 0) {
      return (
        <Typography variant={'subtitle2'} className={classes.red}>
          {t('item.status.unavailable_at_your_location')}
        </Typography>
      );
    }*/ else {
      return <div className={classes.redChip}>{t('item.status.notavailable')}</div>;
    }
  };

  const ItemCard = () => {
    const ownLocationInstances = props.item?.instances?.filter((instance) =>
      filters.ownLocation && userLocation ? instance.locationId === userLocation : true,
    );

    const mostExpensiveItemInstance = ownLocationInstances.reduce(
      (prev, current) => ((prev.price || 0) > (current.price || 0) ? prev : current),
      {} as ItemInstance,
    );
    const cheapestItemInstance = ownLocationInstances.reduce(
      (prev, current) => (!prev.itemInstanceId ? current : (prev.price || 0) < (current.price || 0) ? prev : current),
      {} as ItemInstance,
    );

    return (
      <Card variant={'outlined'} className={[classes.itemContent, classes.h100].join(' ')}>
        <CardMedia
          className={classes.itemContentImageThumb}
          image={imageData?.url ?? dummySvg}
          title={props.item.name}
          style={{ backgroundSize: 'contain', backgroundColor: '#fff' }}
        />
        <CardContent>
          <Typography variant={'subtitle1'} className={classes.itemName}>
            {props.item.name}
          </Typography>
          {props.availableAmount !== undefined && borrowStatus(props.availableAmount || 0, props.availableItems)}
          {props.instance && (
            <div>
              <p>{props.instance.size !== null ? t('instance.size') + ': ' + props.instance.size : undefined}</p>
              {location && location.usePayments && (
                <p>
                  <PriceDisplay instance={props.instance} />
                </p>
              )}
            </div>
          )}
          {!props.instance &&
          cheapestItemInstance &&
          mostExpensiveItemInstance &&
          cheapestItemInstance.itemInstanceId &&
          mostExpensiveItemInstance.itemInstanceId &&
          cheapestItemInstance.price !== mostExpensiveItemInstance.price ? (
            <div>
              <PriceDisplay showTitle={false} instance={cheapestItemInstance} instance2={mostExpensiveItemInstance} />
            </div>
          ) : (
            !props.instance &&
            props.availableAmount !== 0 && (
              <div>
                <PriceDisplay showTitle={false} instance={mostExpensiveItemInstance} />
              </div>
            )
          )}
        </CardContent>
      </Card>
    );
  };
  return (
    <>
      {props.redirectUrl && (
        <Card variant="outlined" key={1} className={classes.h100}>
          <CardActionArea
            className={classes.h100}
            onClick={() => {
              if (props.redirectUrl) {
                history.push(`${props.redirectUrl}/${itemId}`);
              }
            }}
          >
            <ItemCard />
          </CardActionArea>
        </Card>
      )}
      {!props.redirectUrl && <ItemCard key={2} />}
    </>
  );
}

export default ItemComponent;
