import { CssBaseline, Toolbar, Typography } from '@material-ui/core';
import numeral from 'numeral';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { FrameworkAppBar, FrameworkDrawer } from './components';
import ProtectedRoute from './components/protected-route/ProtectedRoute';
import SearchBar from './components/search/SearchBar';
import { URLS } from './constants';
import Footer from './Footer';
import { getUserFromStorage } from './services/authenticationService';
import locationService from './services/locationService';
import { setLocations } from './store/locationsSlice';
import useStyles from './styles';
import {
  AdminBorrowingsView,
  AdminDashboard,
  AdminFeedback,
  AdminHelpView,
  AdminLogs,
  AdminModifyHelpLegal,
  AdminNewInstanceView,
  AdminNewItemView,
  AdminNewLocationView,
  AdminNewNewsArticleView,
  AdminNewUserView,
  AdminOldInstanceView,
  AdminOldItemView,
  AdminOldLocationView,
  AdminOldNewsArticleView,
  AdminOldUserView,
  AdminOrdersView,
  AdminSearchItemsView,
  AdminSearchLocationsView,
  AdminSearchNewsView,
  AdminSearchUsersView,
  BorrowingsView,
  DetailedInstancePage,
  DetailedItemPage,
  HelpView,
  HomeView,
  LegalView,
  LendSelect,
  LendWithCode,
  LikesView,
  Login,
  LoginRedirect,
  Logout,
  LogoutRedirect,
  NewsArticleView,
  NewsPage,
  NotFoundPage,
  NotPaidView,
  PaymentMethodsView,
  ScanView,
  SearchPage,
  UserProfilePage,
} from './views';
import AdminProblemsView from './views/admin/AdminProblemsView';
import { BorrowingPage } from './views/eu/BorrowingPage';
import { ReservationPage } from './views/eu/ReservationPage';

require('numeral/locales/fi');
numeral.locale('fi');

function AppSwitcher(): JSX.Element {
  return (
    <Switch>
      {/* User navigation & authentication*/}
      <Route exact path={URLS.ROOT} component={HomeView} />
      <Route exact path={URLS.EU.NEWS} component={NewsPage} />
      <Route exact path={URLS.EU.NOTPAID} component={NotPaidView} />
      <Route path={URLS.EU.NEWSARTICLE} component={NewsArticleView} />
      <Route exact path={URLS.EU.SEARCH} component={SearchPage} />
      <Route path={URLS.EU.SEARCH_Q} component={SearchPage} />
      <Route exact path={URLS.EU.HELP} component={HelpView} />
      <Route exact path={URLS.EU.LEGAL} component={LegalView} />
      <Route exact path={URLS.AUTH.LOGIN} component={Login} />

      <Route exact path={URLS.AUTH.LOGIN_CB} component={LoginRedirect} />
      <Route exact path={URLS.AUTH.LOGOUT} component={Logout} />
      <Route exact path={URLS.AUTH.LOGOUT_CB} component={LogoutRedirect} />
      <ProtectedRoute path={URLS.EU.SCANNING} component={ScanView} />
      <ProtectedRoute path={URLS.EU.LEND_SELECT} component={LendSelect} />
      <ProtectedRoute path={URLS.EU.LEND_ITEM} component={LendWithCode} />
      <ProtectedRoute exact path={URLS.EU.LIKES} component={LikesView} />
      <ProtectedRoute path={URLS.EU.INSTANCE} component={DetailedInstancePage} />
      <Route path={URLS.EU.ITEM} component={DetailedItemPage} />
      <ProtectedRoute exact path={URLS.EU.BORROWS} component={BorrowingsView} />
      <ProtectedRoute exact path={URLS.EU.BORROWING} component={BorrowingPage} />
      <ProtectedRoute exact path={URLS.EU.RESERVING} component={ReservationPage} />
      <ProtectedRoute exact path={URLS.EU.PAYMENTMETHODS} component={PaymentMethodsView} />
      <ProtectedRoute exact path={URLS.EU.PROFILE} component={UserProfilePage} />
      {/* Admin borrows */}
      <ProtectedRoute exact path={URLS.ADMIN.BORROWINGS} component={AdminBorrowingsView} />
      {/* Admin items */}
      <ProtectedRoute exact path={URLS.ADMIN.ITEMS} component={AdminSearchItemsView} />
      <ProtectedRoute path={URLS.ADMIN.ITEMS_SEARCH} component={AdminSearchItemsView} />
      <ProtectedRoute exact path={URLS.ADMIN.INSTANCE_NEW} component={AdminNewInstanceView} />
      <ProtectedRoute exact path={URLS.ADMIN.ITEM_NEW} component={AdminNewItemView} />
      <ProtectedRoute path={URLS.ADMIN.INSTANCE_OLD} component={AdminOldInstanceView} />
      <ProtectedRoute path={URLS.ADMIN.ITEM_OLD} component={AdminOldItemView} />
      {/* Admin locations */}
      <ProtectedRoute exact path={URLS.ADMIN.LOCATIONS} component={AdminSearchLocationsView} />
      <ProtectedRoute path={URLS.ADMIN.LOCATIONS_SEARCH} component={AdminSearchLocationsView} />
      <ProtectedRoute exact path={URLS.ADMIN.LOCATION_NEW} component={AdminNewLocationView} />
      <ProtectedRoute path={URLS.ADMIN.LOCATION_OLD} component={AdminOldLocationView} />
      {/* Admin news */}
      <ProtectedRoute exact path={URLS.ADMIN.NEWS} component={AdminSearchNewsView} />
      <ProtectedRoute path={URLS.ADMIN.NEWS_SEARCH} component={AdminSearchNewsView} />
      <ProtectedRoute exact path={URLS.ADMIN.NEWSARTICLE_NEW} component={AdminNewNewsArticleView} />
      <ProtectedRoute path={URLS.ADMIN.NEWSARTICLE_OLD} component={AdminOldNewsArticleView} />
      {/* Admin users */}
      <ProtectedRoute exact path={URLS.ADMIN.USERS} component={AdminSearchUsersView} />
      <ProtectedRoute path={URLS.ADMIN.USERS_SEARCH} component={AdminSearchUsersView} />
      <ProtectedRoute exact path={URLS.ADMIN.USER_NEW} component={AdminNewUserView} />
      <ProtectedRoute path={URLS.ADMIN.USER_OLD} component={AdminOldUserView} />
      {/* Admin other */}
      <ProtectedRoute exact path={URLS.ADMIN.DASHBOARD} component={AdminDashboard} />
      <ProtectedRoute exact path={URLS.ADMIN.HELP} component={AdminHelpView} />
      <ProtectedRoute exact path={URLS.ADMIN.FEEDBACK} component={AdminFeedback} />
      <ProtectedRoute exact path={URLS.ADMIN.LOGS} component={AdminLogs} />
      <ProtectedRoute exact path={URLS.ADMIN.MODIFY} component={AdminModifyHelpLegal} />
      <ProtectedRoute exact path={URLS.ADMIN.PROBLEMS} component={AdminProblemsView} />

      <ProtectedRoute exact path={URLS.ADMIN.ORDERS} component={AdminOrdersView} />
      {/* Everything else */}
      <Route component={NotFoundPage} />
    </Switch>
  );
}

function App(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    getUserFromStorage();
  }, []);

  useEffect(() => {
    locationService.fetchAll().then((locations) => dispatch(setLocations(locations)));
  }, []);

  return (
    <BrowserRouter>
      <div className={classes.appRoot}>
        <CssBaseline />
        <FrameworkAppBar />
        <FrameworkDrawer />
        <main className={classes.appContent}>
          <Toolbar />
          <Route exact path={URLS.ROOT}>
            <div
              className={classes.heroSection}
              style={{
                backgroundImage: 'url(https://www.murrost.fi/wp-content/uploads/2021/10/shareandsave.JPG.jpg)',
              }}
            >
              <div className={classes.heroContent}>
                <Typography variant={'h3'} className={classes.heroTitle}>
                  {t('hero.title')}
                </Typography>

                <Typography variant={'h5'} className={classes.heroSubtitle}>
                  {t('hero.subtitle')}
                </Typography>
                <SearchBar
                  redirectUrl={URLS.EU.SEARCH}
                  searchingFor={'items'}
                  initialQuery={undefined}
                  className={classes.heroSearch}
                  inputClassName={classes.heroSearchInput}
                />
              </div>
            </div>
          </Route>

          <div className={classes.hMargin}>
            <AppSwitcher />
          </div>
          <Footer />
        </main>
      </div>
    </BrowserRouter>
  );
}

export default App;
